import React, { FunctionComponent, useState } from 'react';
import { TablePaginationProps, Typography, Button, TableRow, TableCell, Link as MUILink } from '@material-ui/core';
import { usePaginatedQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { LoadingIndicator, ContentWrapper, SortableTable, StyledDivider } from '../../components';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { SuppliersResponse } from '../../types/supplier';
import { useClientId } from '../Routes/useClientId';
import styled from 'styled-components';

const InfoWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const StyledTypography = styled(Typography)`
  padding: 10px 15px;
  margin: 10px 0;
`;

type SuppliersProps = {
  isDashboard?: boolean;
};

export const Suppliers: FunctionComponent<SuppliersProps> = ({ isDashboard }) => {
  const clientId = useClientId();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('supplier_name');

  const { isLoading, data, error, refetch } = usePaginatedQuery<SuppliersResponse, ApiError>(
    ['suppliers-list', page, rowsPerPage, clientId, sortOrder, sortBy],
    ApiService.get(`/client/${clientId}/suppliers-list`, {
      page: page,
      rowsPerPage: rowsPerPage,
      sortBy,
      order: sortOrder,
    })
  );

  const handleRefetch = () => refetch();

  const handleChangePage: TablePaginationProps['onChangePage'] = (e, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!!error || !data) {
    return (
      <InfoWrapper>
        <Typography variant="body2">{error && ApiService.getErrorMessage(error.response.data.code)}</Typography>
        <Button color="primary" onClick={handleRefetch}>
          Pokušaj ponovno
        </Button>
      </InfoWrapper>
    );
  }
  const head = [
    {
      id: 'order_number',
      label: 'Redni broj',
      sortable: false,
    },
    {
      id: 'supplier_name',
      label: 'Ime',
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: false,
    },
    {
      id: 'telephone',
      label: 'Telefon',
      sortable: false,
    },
    {
      id: 'details',
      label: 'Detalji',
      sortable: false,
    },
  ];

  const paginationProps = {
    rowsPerPage: rowsPerPage,
    page: page,
    count: parseInt(data.count || '0'),
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const tableData = data.suppliers.map((el, i) => (
    <TableRow key={el.id}>
      <TableCell>{i + 1}</TableCell>
      <TableCell>{el.supplierName}</TableCell>
      <TableCell>{el.email}</TableCell>
      <TableCell>{el.telephone}</TableCell>
      <TableCell>
        <MUILink component={Link} to={`/client/${clientId}/supplier/${el.id}`}>
          <Typography variant="button">Detalji</Typography>
        </MUILink>
      </TableCell>
    </TableRow>
  ));

  return (
    <ContentWrapper>
      {isDashboard ? (
        <StyledTypography variant="h6">Lista dobavljača</StyledTypography>
      ) : (
        <>
          <Typography variant="h6">Lista dobavljača</Typography>
          <StyledDivider />
        </>
      )}
      {tableData.length > 0 ? (
        <SortableTable
          alignHead="left"
          head={head}
          pagination={paginationProps}
          orderBy={sortBy}
          order={sortOrder}
          onRequestSort={(_, sortProp) => {
            setSortBy(sortProp);
            setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        >
          {tableData}
        </SortableTable>
      ) : (
        <InfoWrapper>
          <Typography variant="body2">Nema podataka za prikaz</Typography>
        </InfoWrapper>
      )}
    </ContentWrapper>
  );
};
