import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TablePaginationProps, Typography, Button, TableRow, TableCell, Paper } from '@material-ui/core';
import { usePaginatedQuery } from 'react-query';
import { ApiService } from '../../services/api';
import { SortableTable, LoadingIndicator } from '../../components/index';
import { ApiError } from '../../types/api_error';
import { DraftInvoicesResponse } from '../../types/invoice';
import styled from 'styled-components';
import { useClientId } from '../Routes/useClientId';
import { omit as _omit } from 'lodash';

const ErrorWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px;
`;

const DraftInvoicesWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`;

export const DraftInvoices: FunctionComponent<{ mplaceId?: string }> = ({ mplaceId }) => {
  const clientId = useClientId();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState<string | number>('month');

  const { data, isLoading, error, isError, refetch } = usePaginatedQuery<DraftInvoicesResponse, ApiError>(
    ['draft-invoices', page, rowsPerPage, clientId, sortOrder, sortBy],
    ApiService.get(`/client/${clientId}/draft-invoices`, {
      page,
      rowsPerPage,
      sortBy,
      order: sortOrder,
    })
  );

  const handleChangePage: TablePaginationProps['onChangePage'] = (e, newPage) => setPage(newPage);
  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const head = [
    {
      id: 'order_number',
      label: 'Redni broj',
      sortable: false,
    },
    {
      id: 'customer_name',
      label: 'Platitelj',
      sortable: true,
    },
    {
      id: 'amount',
      label: 'Iznos',
      sortable: true,
    },
    {
      id: 'month',
      label: 'Period',
      sortable: true,
    },
  ];

  return (
    <DraftInvoicesWrapper>
      <StyledTableHeader>
        <Typography variant="h6">Nedovršeni računi</Typography>
      </StyledTableHeader>
      {isError || !data ? (
        <ErrorWrapper>
          <Typography variant="body2">{error && ApiService.getErrorMessage(error.response.data.code)}</Typography>
          <Button color="primary" onClick={() => refetch()}>
            Pokušaj ponovo
          </Button>
        </ErrorWrapper>
      ) : (
        <SortableTable
          head={head}
          pagination={{
            page,
            rowsPerPage,
            count: parseInt(data.count || '0'),
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          orderBy={sortBy}
          order={sortOrder}
          onRequestSort={(_, sortProp) => {
            setSortBy(sortProp);
            setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        >
          {data.draftInvoices.map((invoice, i) => {
            const modifiedInvoice = { ..._omit(invoice, 'id'), invoiceOriginalId: invoice.id };
            return (
              <StyledTableRow
                key={i}
                onClick={() =>
                  history.push(`/client/${clientId}/create-invoice`, { invoice: { ...modifiedInvoice, mplaceId } })
                }
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell>{invoice.customerName}</TableCell>
                <TableCell>{invoice.amount}</TableCell>
                <TableCell>{`${invoice.month} / ${invoice.year}`}</TableCell>
              </StyledTableRow>
            );
          })}
        </SortableTable>
      )}
    </DraftInvoicesWrapper>
  );
};
