import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const LoadingIndicator: FunctionComponent = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
);
