import React, { FunctionComponent, useState } from 'react';
import { Button, Typography, TextField, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { CustomModal, StyledDivider } from '../../components/index';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

type AdditionalItemProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  additionalItems: string[];
  setChosenItems: (item: string[]) => void;
  chosenItems: string[];
};

export const AdditionalItemPickerModal: FunctionComponent<AdditionalItemProps> = ({
  open,
  handleClose,
  handleSubmit,
  additionalItems,
  setChosenItems,
  chosenItems,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <CustomModal open={open} onClose={handleClose}>
      <>
        <Typography variant="body1">Odaberite dodatne stavke koje će biti prikazane na grafu</Typography>
        <StyledDivider />
        <Autocomplete
          multiple
          options={additionalItems || []}
          disableCloseOnSelect
          getOptionLabel={(option) => option.toUpperCase()}
          onChange={(_, value) => {
            setIsDirty(true);
            setChosenItems(value);
          }}
          renderOption={(option, { selected }) => {
            return (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={chosenItems.includes(option)}
                />
                {option.toUpperCase()}
              </>
            );
          }}
          style={{ width: 500 }}
          value={chosenItems}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Odaberite dodatne stavke" placeholder="Dodatne stavke" />
          )}
        />
        <ButtonsWrapper>
          <Button
            disabled={!isDirty}
            type="submit"
            color="primary"
            onClick={() => {
              setIsDirty(false);
              handleSubmit();
            }}
          >
            Potvrdi
          </Button>
          <Button onClick={handleClose} color="default" type="button">
            Odustani
          </Button>
        </ButtonsWrapper>
      </>
    </CustomModal>
  );
};
