import React, { useState, FunctionComponent } from 'react';
import { Bar, Line } from '@reactchartjs/react-chart.js';
import { useQuery } from 'react-query';
import { Typography, Button, Radio, RadioGroup, FormControlLabel, IconButton } from '@material-ui/core';
import { ApiService } from '../../services/api';
import styled from 'styled-components';
import { ApiError } from '../../types/api_error';
import { GraphResponse } from '../../types/dashboard_data';
import { FilterList, Settings } from '@material-ui/icons';
import { omit, isEqual } from 'lodash';
import { useClientId } from '../Routes/useClientId';
import { LoadingIndicator, AnnualGraphModal } from '../../components';
import { getGraphContent, getGraphOptions } from './GraphUtils';
import { GraphLabels } from '../../types/graph';
import { AdditionalItemPickerModal } from './AdditionalItemsPicker';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  flex: 1;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  padding: 4px !important;
  border-radius: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 12px;
  }
`;

const IconButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

type AnnualGraphTypes = 'type' | 'classificationCode' | 'name';

interface AdditionalGraphProps {
  mplaceId?: string;
  additional: GraphLabels;
  setAdditional: React.Dispatch<React.SetStateAction<GraphLabels>>;
  isLineChart: string;
  setIsLineChart: (name: string) => void;
  year?: number;
  setYear: React.Dispatch<React.SetStateAction<number | undefined>>;
  annualGraphType: AnnualGraphTypes;
  setAnnualGraphType: React.Dispatch<React.SetStateAction<AnnualGraphTypes>>;
}

export const AnnualGraph: FunctionComponent<AdditionalGraphProps> = ({
  mplaceId,
  additional,
  setAdditional,
  isLineChart,
  setIsLineChart,
  year,
  setYear,
  annualGraphType,
  setAnnualGraphType,
}) => {
  const clientId = useClientId();
  const [choosenItems, setChoosenItems] = useState<string[]>([]);
  const [graphReady, setGraphReady] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [additionalItemsPickerOpen, setAdditionalItemsPickerOpen] = useState<boolean>(false);

  const { data, isLoading, isError, refetch } = useQuery<GraphResponse, ApiError>(
    ['invoice-annual-consumption'],
    ApiService.get(`/client/${clientId}/invoices/annual-consumption`, {
      year: year?.toString(),
      mplaceId,
      variant: annualGraphType,
      filters: choosenItems,
    }),
    {
      onSuccess: (data) => {
        const mappedAdditional: GraphLabels = {};
        data.labels.forEach((label) => (mappedAdditional[label.toUpperCase()] = true));
        if (!isEqual(Object.keys(omit(additional, ['UKUPNO'])), Object.keys(mappedAdditional)))
          setAdditional({ ...additional, ...mappedAdditional });
        setGraphReady(true);
      },
    }
  );

  const handleModalSubmit = () => {
    setGraphReady(false);
    refetch();
    setModalOpen(false);
  };

  const handleAdditionalModalSubmit = () => {
    setGraphReady(false);
    refetch();
    setAdditionalItemsPickerOpen(false);
  };

  const barOpts = getGraphOptions(
    {
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
        },
      ],
    },
    setAdditional
  );

  const lineOpts = getGraphOptions(
    {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        },
      ],
    },
    setAdditional
  );

  const refetchAll = () => {
    refetch();
  };

  if (isLoading || !graphReady) {
    return <LoadingIndicator />;
  }

  if (!clientId || isError || !data) {
    return (
      <InfoWrapper>
        <Typography variant="body2">Nažalost nismo uspjeli dohvatiti podatke.</Typography>
        <Button color="primary" onClick={refetchAll}>
          Pokušaj ponovno
        </Button>
      </InfoWrapper>
    );
  }

  const barGraphContent = getGraphContent(data, additional, true);
  const lineGraphContent = getGraphContent(data, additional, false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLineChart(event.target.value);
  };

  return (
    <GraphWrapper>
      <HeaderRow>
        <StyledRadioGroup aria-label="gender" name="gender1" value={isLineChart} onChange={handleChange}>
          <StyledFormControlLabel value="bar" control={<Radio />} label="Stupčasti prikaz" />
          <StyledFormControlLabel value="line" control={<Radio />} label="Linijski prikaz" />
        </StyledRadioGroup>
        <IconButtonGroup>
          <StyledIconButton onClick={() => setModalOpen(true)}>
            <Settings color="primary" />
            <Typography variant="caption">Podesite graf</Typography>
          </StyledIconButton>
          {annualGraphType !== 'type' && (
            <StyledIconButton onClick={() => setAdditionalItemsPickerOpen(true)}>
              <FilterList color="primary" />
              <Typography variant="caption">Filtrirajte stavke</Typography>
            </StyledIconButton>
          )}
        </IconButtonGroup>
      </HeaderRow>
      {isLineChart === 'line' ? (
        <Line type="line" data={lineGraphContent} options={lineOpts} />
      ) : (
        <Bar type="bar" data={barGraphContent} options={barOpts} />
      )}
      <AnnualGraphModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        year={year}
        setYear={setYear}
        handleSubmit={handleModalSubmit}
        title="Filtri za graf godišnje potrošnje"
        variant={annualGraphType}
        setVariant={setAnnualGraphType}
      />
      <AdditionalItemPickerModal
        open={additionalItemsPickerOpen}
        handleClose={() => setAdditionalItemsPickerOpen(false)}
        handleSubmit={handleAdditionalModalSubmit}
        additionalItems={data?.options || []}
        setChosenItems={setChoosenItems}
        chosenItems={choosenItems}
      />
    </GraphWrapper>
  );
};
