import React, { FunctionComponent, useContext } from 'react';
import { List, Drawer, Toolbar, useMediaQuery, Divider } from '@material-ui/core';
import { SideMenuLink } from './SideMenuLink';
import styled from 'styled-components';
import {
  Dashboard,
  Style,
  GroupAdd,
  Receipt,
  Place,
  SupervisorAccount,
  Email,
  Publish,
  Label,
  AssignmentInd,
} from '@material-ui/icons';
import { useQuery } from 'react-query';
import { ApiService } from '../services/api';
import { DraftInvoicesResponse } from '../types/invoice';
import { ApiError } from '../types/api_error';
import { AppContext } from '../context/app';

const DrawerWrapper = styled(Drawer)`
  width: 260px;
  min-width: 260px;
  max-width: 260px;

  & .MuiPaper-root {
    width: 100% !important;
    min-width: 260px;
    max-width: 260px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 15px 0;
`;

type UserClientRole = {
  [key: number]: number;
};

type SideMenuProps = {
  userClientRoles: UserClientRole;
  drawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  clientId: number;
};

export const SideMenu: FunctionComponent<SideMenuProps> = ({
  userClientRoles,
  drawerOpen,
  setDrawerOpen,
  clientId,
}) => {
  const { isSuperadmin } = useContext(AppContext);
  const matches = useMediaQuery('(min-width:1025px)');
  const { data } = useQuery<DraftInvoicesResponse, ApiError>(
    'drafts-count',
    ApiService.get(`/client/${clientId}/draft-invoices`),
    {
      enabled: userClientRoles[clientId] >= 20 || isSuperadmin,
    }
  );

  return (
    <DrawerWrapper
      variant={matches ? 'permanent' : 'temporary'}
      data-testid="side-menu"
      open={drawerOpen}
      onClick={!matches ? () => setDrawerOpen(false) : undefined}
    >
      <Toolbar />
      <List>
        <SideMenuLink title="Kontrolna ploča" navigateTo={`/client/${clientId}/dashboard`} icon={<Dashboard />} />
        {(userClientRoles[clientId] >= 20 || isSuperadmin) && (
          <SideMenuLink title="Tipovi" navigateTo={`/client/${clientId}/invoice-types`} icon={<Style />} />
        )}
        {(userClientRoles[clientId] >= 20 || isSuperadmin) && (
          <SideMenuLink
            title="Klasifikacijski kodovi"
            navigateTo={`/client/${clientId}/classificationCodes`}
            icon={<Label />}
          />
        )}
        <SideMenuLink title="Dobavljači računa" navigateTo={`/client/${clientId}/suppliers`} icon={<AssignmentInd />} />
        {(userClientRoles[clientId] >= 10 || isSuperadmin) && (
          <SideMenuLink
            title="Dodavanje računa"
            navigateTo={`/client/${clientId}/upload-invoice`}
            icon={<Receipt />}
            badgeText={data?.count}
          />
        )}
        {(userClientRoles[clientId] >= 10 || isSuperadmin) && (
          <SideMenuLink
            title="Originalni računi"
            navigateTo={`/client/${clientId}/uploaded-invoices`}
            icon={<Publish />}
          />
        )}
        {(userClientRoles[clientId] >= 20 || isSuperadmin) && (
          <SideMenuLink title="Korisnici" navigateTo={`/client/${clientId}/users`} icon={<GroupAdd />} />
        )}
        <SideMenuLink title="Mjerna mjesta" navigateTo={`/client/${clientId}/measurement-places`} icon={<Place />} />
        <StyledDivider />
        <SideMenuLink title="Promjena mail adrese" navigateTo={`/client/${clientId}/email/`} icon={<Email />} />
        {isSuperadmin && (
          <>
            <StyledDivider />
            <SideMenuLink
              title="Lista klijenata"
              navigateTo={`/client/${clientId}/clients`}
              icon={<SupervisorAccount />}
            />
          </>
        )}
      </List>
    </DrawerWrapper>
  );
};
