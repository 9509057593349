import React, { FunctionComponent, useContext } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { User } from '../../types/users';
import { AppContext } from '../../context/app';
import { ClientRoutes } from './ClientRoutes';
import { ClientRedirect } from './ClientRedirect';
import { Client } from '../../types/client';
import { LoadingIndicator } from '../../components/LoadingIndicator';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AppRoutes: FunctionComponent = () => {
  const { path } = useRouteMatch();
  const { setUserId, setIsSuperadmin, userId } = useContext(AppContext);
  const { data: userData, isLoading: userIsLoading, error: userError } = useQuery<User, ApiError>(
    'init-user-fetch',
    ApiService.get('/self'),
    {
      onSuccess: (data) => {
        if (!userId) {
          setUserId(data.id);
          setIsSuperadmin(data.isSuperadmin);
        }
      },
      retry: false,
    }
  );

  const { data: clientsData, isLoading: clientsIdLoading, error: clientsError } = useQuery<Client[], ApiError>(
    'getting-clients',
    ApiService.get('/self/clients'),
    {
      retry: false,
    }
  );

  if (userIsLoading || clientsIdLoading) {
    return <LoadingIndicator />;
  }

  if (userError || clientsError || !userData || !clientsData) {
    const error = userError || clientsError;
    const errorCode = error?.response.data.code;
    if (errorCode === 401) {
      return <Redirect to="/login" />;
    } else {
      return <Redirect to={{ pathname: '/error', state: { error } }} />;
    }
  }

  return (
    <AppWrapper>
      <Switch>
        <Route
          exact
          path={path}
          render={(props) => <ClientRedirect {...props} defaultClientId={clientsData[0].id} />}
        />
        <Route path={`${path}/:clientId`} render={(props) => <ClientRoutes {...props} clients={clientsData} />} />
      </Switch>
    </AppWrapper>
  );
};
