import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator, ContentWrapper, StyledDivider } from '../../components/index';
import { AppContext } from '../../context/app';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { ClassificationCodesResponse } from '../../types/classification_codes';
import { useClientId } from '../Routes/useClientId';
import { ClassCodeRow } from './ClassCodeRow';
import { SortableTable } from '../../components/index';

export const ClassificationCodes: FunctionComponent = (): JSX.Element => {
  const clientId = useClientId();
  const [currentEditingCode, setCurrEditingCode] = useState<number | null>(null);
  const { setErrorMessage } = useContext(AppContext);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('classification_code');

  const { data, isLoading, isError, error, refetch } = useQuery<ClassificationCodesResponse[], ApiError>(
    ['classificationCodes', sortBy, sortOrder],
    ApiService.get(`/client/${clientId}/classificationCodes`, {
      sortBy,
      order: sortOrder,
    })
  );
  const [editCode] = useMutation<null, ApiError, { path: string; data: { newCode: string; oldCode: string } }, unknown>(
    ApiService.put(),
    {
      onError: (error) => {
        setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      },
      onSuccess: () => {
        setCurrEditingCode(null);
        refetch();
      },
    }
  );
  if (isLoading) return <LoadingIndicator />;

  if (!data || isError) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const mappedData = data.map((el) => {
    return (
      <ClassCodeRow
        key={el.id}
        classificationCode={el.classificationCode}
        editMode={currentEditingCode === el.id}
        initialCode={el.classificationCode}
        setEditMode={setCurrEditingCode}
        editCodeHandler={editCode}
        classificationCodeId={el.id}
      />
    );
  });

  return (
    <ContentWrapper>
      <Typography variant="h6">Klasifikacijski kodovi podstavki računa</Typography>
      <Typography variant="body1">
        U tablici su prikazani svi pronađeni klasifikacijski kodovi vezani uz račune trenutnog klijenta
      </Typography>
      <StyledDivider />
      <SortableTable
        head={[
          { id: 'classification_code', label: 'Klasifikacijski kod', sortable: true },
          // { id: 'edit', label: 'Uredi', sortable: false },
        ]}
        orderBy={sortBy}
        order={sortOrder}
        onRequestSort={(_, sortProp) => {
          setSortBy(sortProp);
          setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        }}
      >
        {mappedData}
      </SortableTable>
    </ContentWrapper>
  );
};
