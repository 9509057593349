import React, { useState, useCallback, FunctionComponent, useContext } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Typography, Button, Paper, Container } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { theme } from '../../theme';
import { ApiService } from '../../services/api';
import { AppContext } from '../../context/app';
import { ApiError } from '../../types/api_error';
import { isEmpty as _isEmpty } from 'lodash';
import { DraftInvoices } from './DraftInvoices';
import { useClientId } from '../Routes/useClientId';
import { LoadingIndicator, StyledDivider, ContentWrapper } from '../../components';
import { Invoice } from '../../types/invoice';

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 2px dashed ${(props: { isDragActive: boolean }) => (props.isDragActive ? theme.palette.success.main : '#ccc')};
  border-radius: 20px;
  outline: none;
  &:focus,
  &:active {
    border-radius: 20px;
  }
  background-color: ${(props: { isDragActive: boolean }) =>
    props.isDragActive ? theme.palette.success.main + '44' : theme.palette.background.default};
  transition: all 0.25s ease;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
`;

const ButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-self: flex-end;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const UploadInvoice: FunctionComponent = () => {
  const { setErrorMessage } = useContext(AppContext);
  const [file, setFile] = useState<string | Blob>('');
  const history = useHistory();
  const clientId = useClientId();
  const { state } = useLocation<{ mplaceId?: string }>();
  const queryCache = useQueryCache();

  const [postOriginalInvoice, { isLoading, reset }] = useMutation<
    Invoice,
    ApiError,
    { path: string; data: FormData },
    unknown
  >(ApiService.post(), {
    onError: (error) => {
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      reset();
    },
    onSuccess: (data) => {
      const query = queryCache.getQuery('drafts-count');
      if (query) {
        query.refetch();
      }
      history.push(`/client/${clientId}/create-invoice`, {
        invoice: { ...data, mplaceId: state?.mplaceId },
      });
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    if (!_isEmpty(acceptedFiles)) {
      if (acceptedFiles[0].type !== 'text/xml') {
        alert('Pograšan tip datoteke, potreban je XML tip');
        return;
      }
      setFile(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

  const sendFileHandler = () => {
    const formData = new FormData();
    formData.append('file', file);

    postOriginalInvoice({
      path: `/client/${clientId}/invoiceXML`,
      data: formData,
    });
  };

  const renderDropzoneContent = (hasFile: boolean, isDragActive: boolean) => {
    if (isDragActive) {
      return <Typography variant="body1">Ispustite datoteku ovdje</Typography>;
    }
    if (hasFile) {
      return (
        <Typography variant="body1" align="center">
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          {file.name}
        </Typography>
      );
    }
    return (
      <StyledContainer>
        <AddCircleOutline width={48} />
        <Typography variant="body1" align="center">
          Povucite datoteku ili kliknite za dodavanje datoteke
        </Typography>
      </StyledContainer>
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ContentWrapper>
      <Typography variant="h6">Računi</Typography>
      <StyledDivider />
      <StyledPaper>
        <DropzoneWrapper {...getRootProps()} isDragActive={isDragActive}>
          <Input type="file" inputProps={{ ...getInputProps() }} />
          {renderDropzoneContent(!!file, isDragActive)}
        </DropzoneWrapper>
        <ButtonsContainer>
          <Button type="button" color="primary" onClick={sendFileHandler} disabled={!file}>
            Spremi
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() =>
              history.push(`/client/${clientId}/create-invoice`, { invoice: { mplaceId: state?.mplaceId } })
            }
          >
            Unos ručno
          </Button>
        </ButtonsContainer>
      </StyledPaper>
      <DraftInvoices mplaceId={state?.mplaceId} />
    </ContentWrapper>
  );
};
