import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Button, Typography, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';
import { CustomModal, StyledDivider } from './index';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/hr';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const DatePickerWrapper = styled.div`
  margin: 10px 20px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 12px;
  }
`;

type DatePickerProps = {
  open: boolean;
  handleClose: () => void;
  startDate?: string;
  setStartDate: Dispatch<SetStateAction<string | undefined>>;
  endDate?: string;
  setEndDate: Dispatch<SetStateAction<string | undefined>>;
  handleSubmit: () => void;
  title: string;
};

export const DatePickerModal: FunctionComponent<DatePickerProps> = ({
  open,
  handleClose,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit,
  title,
}) => {
  return (
    <CustomModal open={open} onClose={handleClose}>
      <>
        <Typography variant="body1">{title}</Typography>
        <StyledDivider />
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <DateWrapper>
            <DatePickerWrapper>
              <DatePicker
                inputVariant="outlined"
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                label="Od"
                value={startDate}
                maxDate={moment().endOf('month').format('YYYY-MM-DD')}
                onChange={(date) => setStartDate(date?.format('YYYY-MM-01'))}
              />
            </DatePickerWrapper>
            <DatePickerWrapper>
              <DatePicker
                inputVariant="outlined"
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                label="Do"
                value={endDate}
                minDate={startDate}
                maxDate={moment().endOf('year').format('YYYY-MM-DD')}
                onChange={(date) => setEndDate(date?.format('YYYY-MM-DD'))}
              />
            </DatePickerWrapper>
          </DateWrapper>
        </MuiPickersUtilsProvider>
        <ButtonsWrapper>
          <Button type="submit" color="primary" onClick={handleSubmit}>
            Potvrdi
          </Button>
          <Button onClick={handleClose} color="default" type="button">
            Odustani
          </Button>
        </ButtonsWrapper>
      </>
    </CustomModal>
  );
};

type AnnualGraphTypes = 'type' | 'classificationCode' | 'name';

type AnnualGraphModalProps = {
  open: boolean;
  handleClose: () => void;
  year?: number;
  setYear: Dispatch<SetStateAction<number | undefined>>;
  handleSubmit: () => void;
  title: string;
  variant: AnnualGraphTypes;
  setVariant: Dispatch<SetStateAction<AnnualGraphTypes>>;
};

export const AnnualGraphModal: FunctionComponent<AnnualGraphModalProps> = ({
  open,
  handleClose,
  year,
  setYear,
  handleSubmit,
  title,
  variant,
  setVariant,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariant(event.target.value as AnnualGraphTypes);
  };

  return (
    <CustomModal open={open} onClose={handleClose}>
      <>
        <Typography variant="body1">{title}</Typography>
        <StyledDivider />
        <Typography variant="subtitle2">Odaberite godinu</Typography>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <DateWrapper>
            <DatePickerWrapper>
              <DatePicker
                inputVariant="outlined"
                variant="inline"
                openTo="year"
                views={['year']}
                label="Godina"
                value={year?.toString()}
                maxDate={(moment().get('year') + 1).toString()}
                onChange={(date) => setYear(date?.get('year'))}
              />
            </DatePickerWrapper>
          </DateWrapper>
        </MuiPickersUtilsProvider>
        <Typography variant="subtitle2">Odaberite podatke za prikaz</Typography>
        <StyledRadioGroup aria-label="gender" name="gender1" value={variant} onChange={handleChange}>
          <StyledFormControlLabel value="type" control={<Radio />} label="Po tipu" />
          <StyledFormControlLabel
            value="classificationCode"
            control={<Radio />}
            label="Dodatne stavke po klasifikacijskom kodu"
          />
          <StyledFormControlLabel value="name" control={<Radio />} label="Dodatne stavke po imenu" />
        </StyledRadioGroup>
        <ButtonsWrapper>
          <Button type="submit" color="primary" onClick={handleSubmit}>
            Potvrdi
          </Button>
          <Button onClick={handleClose} color="default" type="button">
            Odustani
          </Button>
        </ButtonsWrapper>
      </>
    </CustomModal>
  );
};
