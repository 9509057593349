import { Snackbar } from '@material-ui/core';
import { theme } from '../theme';
import styled from 'styled-components';

export const ErrorSnackbar = styled(Snackbar)`
  div {
    background-color: ${theme.palette.error.main} !important;
    color: white;
    border-radius: 10px;
  }
`;
