import React, { FunctionComponent } from 'react';
import { ApiError } from '../../types/api_error';
import { Invoice, InvoiceOriginal } from '../../types/invoice';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { Typography, Button, TablePaginationProps } from '@material-ui/core';
import { InvoicesTableRow } from '../shared/InvoicesTableRow';
import { LoadingIndicator, SortableTable } from '../../components/index';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
`;

const isOriginalInvoice = (invoice: Invoice | InvoiceOriginal): invoice is InvoiceOriginal => {
  return 'mplaceName' in invoice;
};

interface Head {
  id: string;
  label: string;
  sortable: boolean;
}
interface MplaceInvoicesTableContentProps {
  isLoading: boolean;
  isError: boolean;
  data?: (Invoice | InvoiceOriginal)[];
  error: ApiError | null;
  refetch: () => {};
  routePath?: string;
  pagination: TablePaginationProps;
  head: Head[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof { [key: string]: unknown }) => void;
  orderBy: string | number;
  order: 'asc' | 'desc';
}

export const MplaceInvoicesTableContent: FunctionComponent<MplaceInvoicesTableContentProps> = ({
  isLoading,
  isError,
  data,
  error,
  refetch,
  routePath,
  pagination,
  head,
  onRequestSort,
  orderBy,
  order,
}) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (isError || !data) {
    return (
      <FlexWrapper>
        <Typography color="error">{ApiService.getErrorMessage((error as ApiError).response.data.code)}</Typography>
        <Button onClick={refetch}>Pokušaj ponovno</Button>
      </FlexWrapper>
    );
  }

  const mappedData = data.map((invoice, i) => (
    <InvoicesTableRow
      key={invoice.id}
      orderNumber={i + 1}
      customer={isOriginalInvoice(invoice) ? invoice.mplaceName : invoice.customerName}
      amount={invoice.amount}
      period={`${invoice.month}/${invoice.year}`}
      id={invoice.id}
      downloadLink={(invoice as InvoiceOriginal).fileLink}
      routePath={routePath}
    />
  ));

  return (
    <SortableTable head={head} pagination={pagination} orderBy={orderBy} order={order} onRequestSort={onRequestSort}>
      {mappedData}
    </SortableTable>
  );
};
