import React, { ErrorInfo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
interface State {
  hasError: boolean;
  error?: {
    message?: string;
  };
}

class ErrorBoundary extends React.Component<RouteComponentProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: { message: error.toString() } };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error);
    console.log(errorInfo);
  }

  public render() {
    if (this.state.hasError) this.props.history.push('/error', { error: this.state.error });
    return this.props.children;
  }
}

export const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);
