import React, { FunctionComponent, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { Typography, Button, TextField, Box } from '@material-ui/core';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { ContentWrapper, LoadingIndicator, StyledDivider } from '../../components';

const PageWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledForm = styled.form`
  min-width: 350px;
`;

type FormInput = {
  email: string;
};

const loginSchema = joi.object({
  email: joi
    .string()
    .required()
    .email({ tlds: { allow: false } }),
});

export const LoginPage: FunctionComponent = () => {
  const { register, handleSubmit, errors } = useForm<FormInput>({
    resolver: joiResolver(loginSchema),
  });

  const [login, loginReqInfo] = useMutation<{ id: number }, ApiError, { path: string; data: FormInput }, unknown>(
    ApiService.post()
  );

  const onError = (tryAgain: boolean) => {
    return tryAgain ? loginReqInfo.reset() : null;
  };

  const onSubmit = async (data: FormInput) => {
    await login({ path: '/signin', data });
  };

  const getContent = (): ReactElement => {
    if (loginReqInfo.isLoading) {
      return <LoadingIndicator />;
    } else if (loginReqInfo.isSuccess) {
      return <Typography variant="body1">Na vašu email adresu poslana je poveznica za pristup stranici!</Typography>;
    } else if (loginReqInfo.isError && loginReqInfo.error) {
      return (
        <>
          <Typography variant="body2">{ApiService.getErrorMessage(loginReqInfo.error.response.data.code)}</Typography>
          <Button color="primary" onClick={() => onError(true)}>
            Pokušaj ponovno
          </Button>
        </>
      );
    }
    return (
      <>
        <Typography variant="h6">Unesite svoj email</Typography>
        <Box m={2} />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            inputProps={{ ref: register, name: 'email' }}
            label="Email adresa"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email ? 'Molimo unesite valjanu email adresu' : null}
          />
          <StyledDivider />
          <Button type="submit" color="primary">
            Potvrdi
          </Button>
        </StyledForm>
      </>
    );
  };

  return <PageWrapper>{getContent()}</PageWrapper>;
};
