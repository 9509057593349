import React, { FunctionComponent } from 'react';
import { Paper, Typography, Chip } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../../theme';
import { ApiError } from '../../types/api_error';
import { LoadingIndicator } from '../../components/index';
import { ApiService } from '../../services/api';

const StyledPaper = styled(Paper)`
  padding: 10px;
  display: flex;
  width: 24%;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;

  @media screen and (max-width: 1025px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const ErrorWrapper = styled(StyledPaper)`
  justify-content: center;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  font-weight: 400;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PercentageText = styled(({ isPositive, ...rest }) => <Typography {...rest} />)`
  color: ${(props) => (props.isPositive ? theme.palette.error.main : theme.palette.success.main)};
  font-weight: 600;
  margin-right: 15px;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
`;

interface ConsumptionCardProps {
  amount?: string;
  percentage?: number;
  title: string;
  badgeText: string;
}

const ConsumptionCard: FunctionComponent<ConsumptionCardProps> = ({ amount, percentage, title, badgeText }) => {
  return (
    <StyledPaper>
      <CardHeader>
        <StyledTypography variant="button">{title}</StyledTypography>
        <Chip color="primary" label={badgeText} />
      </CardHeader>
      <StyledTypography variant={percentage ? 'h5' : 'h4'} align={percentage ? 'left' : 'center'}>
        {amount}
      </StyledTypography>
      {percentage ? (
        <CardFooter>
          <PercentageText isPositive={percentage >= 0}>{percentage}%</PercentageText>
          <Typography variant="caption">{percentage >= 0 ? 'Više' : 'Manje'} nego prošli mjesec</Typography>
        </CardFooter>
      ) : (
        <div />
      )}
    </StyledPaper>
  );
};

interface ConsumptionCardContainerProps extends ConsumptionCardProps {
  error: ApiError | null;
  isLoading: boolean;
}

export const ConsumptionCardContainer: FunctionComponent<ConsumptionCardContainerProps> = ({
  error,
  isLoading,
  percentage,
  title,
  badgeText,
  amount,
}) => {
  if (isLoading) {
    return (
      <StyledPaper>
        <LoadingIndicator />
      </StyledPaper>
    );
  }

  if (error) {
    return (
      <ErrorWrapper>
        <Typography variant="body2">
          {ApiService.getErrorMessage(error.response.data.code) || 'Dogodio se problem prilikom dohvaćanja podataka'}
        </Typography>
      </ErrorWrapper>
    );
  }

  return <ConsumptionCard amount={amount} title={title} badgeText={badgeText} percentage={percentage} />;
};
