import React, { FunctionComponent, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { TextField, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { CustomModal, LoadingIndicator } from '../../components/index';
import { ApiError } from '../../types/api_error';

const Divider = styled.div`
  height: 1px;
  margin: 9.5px 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const InfoWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex: 1;
`;

type NewTypeProps = {
  open: boolean;
  handleClose: () => void;
  clientId?: number;
  refetch: () => void;
};

type FormInput = {
  type: string;
};

const typeSchema = joi.object({
  type: joi.string().required(),
});

export const NewType: FunctionComponent<NewTypeProps> = ({ open, handleClose, clientId, refetch }) => {
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: joiResolver(typeSchema),
  });

  const [addType, addTypeReqInfo] = useMutation<{}, ApiError, { path: string; data: { type: string } }, unknown>(
    ApiService.post()
  );

  const onSubmit = async (data: FormInput) => {
    await addType({
      path: `/client/${clientId}/invoice-type`,
      data,
    });
  };

  const onSuccess = () => {
    refetch();
    reset();
    addTypeReqInfo.reset();
    handleClose();
  };

  const handleBackdropClick = () => {
    refetch();
    addTypeReqInfo.reset();
    handleClose();
  };

  const getModalContent = (): ReactElement => {
    if (addTypeReqInfo.isLoading) {
      return <LoadingIndicator />;
    } else if (addTypeReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">{ApiService.getErrorMessage(addTypeReqInfo.error.response.data.code)}</Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={addTypeReqInfo.reset}>
              Pokušaj ponovo
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }
    if (addTypeReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">Tip uspješno dodan</Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              OK
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    return (
      <>
        <Typography variant="h6">Dodavanje novog tipa</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <TextField
            inputProps={{ ref: register, name: 'type' }}
            label="Ime tipa"
            variant="outlined"
            fullWidth
            error={!!errors.type}
            helperText={errors.type ? 'Ime tipa je obavezno' : ''}
          />
          <Divider />
          <ButtonsWrapper>
            <Button type="submit" color="primary">
              Spremi
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </form>
      </>
    );
  };

  return (
    <CustomModal open={open} onClose={handleBackdropClick}>
      {getModalContent()}
    </CustomModal>
  );
};
