import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Typography, Checkbox, TextField, Button } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { LoadingIndicator } from '../../components';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { InvoiceType } from '../../types/invoice_type';
import { ApiError } from '../../types/api_error';
import { ApiService } from '../../services/api';
import { MeasurementPlace } from '../../types/mplace';

const TypeContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonExtended = styled(Button)`
  margin: 10px 0;
`;

const isMeasurementPlace = (data: MeasurementPlace | InvoiceType): data is MeasurementPlace => {
  return 'name' in data;
};

interface FilterInputProps {
  isLoading: boolean;
  isError: boolean;
  data?: (InvoiceType | MeasurementPlace)[];
  error?: ApiError | null;
  refetch: () => {};
  label: string;
  placeholder: string;
  stateSetter: Dispatch<SetStateAction<number[]>>;
}

export const FilterInput: FunctionComponent<FilterInputProps> = ({
  isLoading,
  isError,
  data,
  error,
  refetch,
  label,
  placeholder,
  stateSetter,
}) => {
  const debounceFn = debounce((filterValues: (InvoiceType | MeasurementPlace)[]) => {
    const filters = filterValues.map(({ id }) => id);
    stateSetter(filters);
  }, 800);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return (
      <TypeContentWrapper>
        <Typography variant="body1">{ApiService.getErrorMessage((error as ApiError).response.data.code)}</Typography>
        <ButtonExtended onClick={() => refetch()} color="primary" type="button">
          Pokušaj ponovno
        </ButtonExtended>
      </TypeContentWrapper>
    );
  }

  return (
    <Autocomplete
      multiple
      options={data || []}
      disableCloseOnSelect
      getOptionLabel={(option) => (isMeasurementPlace(option) ? option.name : option.type)}
      onChange={(_, value) => {
        debounceFn(value);
      }}
      renderOption={(option, { selected }) => {
        return (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {isMeasurementPlace(option) ? option.name : option.type}
          </>
        );
      }}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />}
    />
  );
};
