import React, { FunctionComponent, useState } from 'react';
import { TableRow, TableCell, TextField, Typography, IconButton } from '@material-ui/core';
import { Edit, DeleteForever } from '@material-ui/icons';
import { ConfirmationButtons } from '../../components/index';
import { useClientId } from '../Routes/useClientId';
import { MutateFunction } from 'react-query';
import { ApiError } from '../../types/api_error';
import { InvoiceType } from '../../types/invoice_type';

type TypeRowProps = {
  refetch: () => void;
  type: string;
  typeId: number;
  editMode: boolean;
  deleteMode: boolean;
  setEditMode: (id: number | null) => void;
  setDeleteMode: (id: number | null) => void;
  editType: MutateFunction<InvoiceType, ApiError, { path: string; data: { type: string } }, unknown>;
  deleteType: MutateFunction<{}, ApiError, { path: string }, unknown>;
};

export const TypeRow: FunctionComponent<TypeRowProps> = ({
  refetch,
  type,
  typeId,
  editMode,
  deleteMode,
  setEditMode,
  setDeleteMode,
  editType,
  deleteType,
}) => {
  const clientId = useClientId();

  const [typeValue, setTypeValue] = useState<string>(type);

  const editTypeHandler = async () => {
    if (!typeValue.trim()) return;
    await editType({ path: `/client/${clientId}/invoice-type/${typeId}`, data: { type: typeValue } });
    refetch();
    setEditMode(null);
  };

  const deleteTypeHandler = async () => {
    await deleteType({ path: `/client/${clientId}/invoice-type/${typeId}` });
    refetch();
    setDeleteMode(null);
  };

  return (
    <TableRow>
      <TableCell>
        {editMode ? (
          <TextField
            variant="outlined"
            value={typeValue}
            onChange={(e) => {
              setTypeValue(e.target.value);
            }}
          />
        ) : (
          <Typography variant="body2">{type}</Typography>
        )}
      </TableCell>
      <TableCell>
        {!editMode ? (
          <IconButton onClick={() => setEditMode(typeId)} disabled={deleteMode}>
            <Edit color={deleteMode ? 'disabled' : 'primary'} />
          </IconButton>
        ) : (
          <ConfirmationButtons
            confirmHandler={editTypeHandler}
            cancelHandler={() => setEditMode(null)}
            disabled={type === typeValue}
          />
        )}
        {!deleteMode ? (
          <IconButton onClick={() => setDeleteMode(typeId)} disabled={editMode}>
            <DeleteForever color={editMode ? 'disabled' : 'error'} />
          </IconButton>
        ) : (
          <ConfirmationButtons confirmHandler={deleteTypeHandler} cancelHandler={() => setDeleteMode(null)} />
        )}
      </TableCell>
    </TableRow>
  );
};
