import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PieGraph } from './PieGraph';
import { TypeGraphs } from './TypeGraphs';
import { AdditionalGraphs } from './AdditionalGraphs';
import { AdditionalGraphsByName } from './AdditionalGraphsByName';
import { Tabs, Tab, AppBar, Paper } from '@material-ui/core';
import { GraphLabels } from '../../types/graph';
import { theme } from '../../theme';
import { AnnualGraph } from './AnnualGraph';

const GraphsWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none !important;
  background-color: #f7f7f7 !important;
`;

const BiggerPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const SmallerPaper = styled(Paper)`
  display: flex;
  flex: 1;
`;

const StyledGap = styled.div`
  width: 20px;
`;

const StyledTab = styled(({ isActive, ...rest }) => <Tab {...rest} />)`
  background-color: ${(props) => (props.isActive ? theme.palette.background.paper : 'transparent')};
  color: ${theme.palette.primary.main};
  box-shadow: ${(props) => (props.isActive ? '0px 10px 20px -5px rgba(0,0,0,0.75)' : 'null')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.25s ease;

  &:first-child {
    border-top-left-radius: 0px;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type AnnualGraphTypes = 'type' | 'classificationCode' | 'name';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const DashboardGraph: FunctionComponent<{ mplaceId?: string }> = ({ mplaceId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [types, setTypes] = useState<GraphLabels>({ UKUPNO: false });
  const [isLineChartTypes, setIsLineChartTypes] = useState('bar');
  const [isLineChartAdditional, setIsLineChartAdditional] = useState('bar');
  const [additional, setAdditional] = useState<GraphLabels>({ UKUPNO: false });
  const [isLineChartAddtionalByName, setIsLineChartAdditionalByName] = useState('bar');
  const [additionalNames, setAdditionalNames] = useState<GraphLabels>({ UKUPNO: false });
  const [typesStartDate, setTypesStartDate] = useState<string | undefined>(
    moment().subtract(6, 'months').format('YYYY-MM-01')
  );
  const [typesEndDate, setTypesEndDate] = useState<string | undefined>(moment().format('YYYY-MM-DD'));
  const [additionalStartDate, setAdditionalStartDate] = useState<string | undefined>(
    moment().subtract(6, 'months').format('YYYY-MM-01')
  );
  const [additionalEndDate, setAdditionalEndDate] = useState<string | undefined>(moment().format('YYYY-MM-DD'));
  const [additionalByNameStartDate, setAdditionalByNameStartDate] = useState<string | undefined>(
    moment().subtract(6, 'months').format('YYYY-MM-01')
  );
  const [additionalByNameEndDate, setAdditionalByNameEndDate] = useState<string | undefined>(
    moment().format('YYYY-MM-DD')
  );
  const [annualGraphYear, setAnnualGraphYear] = useState<number | undefined>(moment().get('year'));
  const [annualGraphType, setAnnualGraphType] = useState<AnnualGraphTypes>('type');
  const [isLineChartAnnual, setIsLineChartAnnual] = useState('bar');

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <GraphsWrapper>
      <BiggerPaper>
        <StyledAppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                height: '0',
              },
            }}
          >
            <StyledTab label="Prikaz po tipovima" {...a11yProps(0)} isActive={tabIndex === 0} />
            <StyledTab label="Dodatne stavke po kodu" {...a11yProps(1)} isActive={tabIndex === 1} />
            <StyledTab label="Dodatne stavke po imenu" {...a11yProps(2)} isActive={tabIndex === 2} />
            <StyledTab label="Godišnja potrošnja" {...a11yProps(3)} isActive={tabIndex === 3} />
          </Tabs>
        </StyledAppBar>
        <TabPanel index={0} value={tabIndex}>
          <TypeGraphs
            types={types}
            setTypes={setTypes}
            isLineChart={isLineChartTypes}
            setIsLineChart={setIsLineChartTypes}
            startDate={typesStartDate}
            setStartDate={setTypesStartDate}
            endDate={typesEndDate}
            setEndDate={setTypesEndDate}
            mplaceId={mplaceId}
          />
        </TabPanel>
        <TabPanel index={1} value={tabIndex}>
          <AdditionalGraphs
            additional={additional}
            setAdditional={setAdditional}
            isLineChart={isLineChartAdditional}
            setIsLineChart={setIsLineChartAdditional}
            startDate={additionalStartDate}
            setStartDate={setAdditionalStartDate}
            endDate={additionalEndDate}
            setEndDate={setAdditionalEndDate}
            mplaceId={mplaceId}
          />
        </TabPanel>
        <TabPanel index={2} value={tabIndex}>
          <AdditionalGraphsByName
            additional={additionalNames}
            setAdditional={setAdditionalNames}
            isLineChart={isLineChartAddtionalByName}
            setIsLineChart={setIsLineChartAdditionalByName}
            startDate={additionalByNameStartDate}
            setStartDate={setAdditionalByNameStartDate}
            endDate={additionalByNameEndDate}
            setEndDate={setAdditionalByNameEndDate}
            mplaceId={mplaceId}
          />
        </TabPanel>
        <TabPanel index={3} value={tabIndex}>
          <AnnualGraph
            additional={additionalNames}
            setAdditional={setAdditionalNames}
            isLineChart={isLineChartAnnual}
            setIsLineChart={setIsLineChartAnnual}
            year={annualGraphYear}
            setYear={setAnnualGraphYear}
            mplaceId={mplaceId}
            annualGraphType={annualGraphType}
            setAnnualGraphType={setAnnualGraphType}
          />
        </TabPanel>
      </BiggerPaper>
      {!mplaceId && <StyledGap />}
      {!mplaceId && (
        <SmallerPaper>
          <PieGraph />
        </SmallerPaper>
      )}
    </GraphsWrapper>
  );
};
