import React, { FunctionComponent } from 'react';
import {
  TableProps as MUITableProps,
  TablePaginationProps as MUITablePaginationProps,
  TableCellProps,
  TableContainer,
  Table as MUITable,
  TablePagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';

interface TableProps extends MUITableProps {
  alignHead?: TableCellProps['align'];
  head: string[];
  pagination?: MUITablePaginationProps;
}

export const Table: FunctionComponent<TableProps> = ({ head, children, alignHead = 'justify', pagination }) => {
  return (
    <TableContainer component={Paper}>
      <MUITable data-testid="Table_Id">
        <TableHead>
          <TableRow>
            {head.map((td, i) => (
              <TableCell align={alignHead} key={i}>
                {td}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MUITable>
      {pagination && (
        <TablePagination
          component="div"
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          count={pagination.count}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          onChangePage={pagination.onChangePage}
          onChangeRowsPerPage={pagination.onChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};
