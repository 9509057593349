import React, { FunctionComponent, SetStateAction, Dispatch, useState } from 'react';
import { /*IconButton, */ TableCell, TableRow, TextField, Typography } from '@material-ui/core';
// import { Edit } from '@material-ui/icons';
// import { ConfirmationButtons } from '../../components';
import { MutateFunction } from 'react-query';
import { ApiError } from '../../types/api_error';
// import { useClientId } from '../Routes/useClientId';

interface ClassCodeRowProps {
  editCodeHandler: MutateFunction<null, ApiError, { path: string; data: { newCode: string; oldCode: string } }>;
  setEditMode: Dispatch<SetStateAction<number | null>>;
  classificationCode: string;
  editMode: boolean;
  classificationCodeId: number;
  initialCode: string;
}

// TODO: temporarly disabled edit action for classification code
export const ClassCodeRow: FunctionComponent<ClassCodeRowProps> = ({
  editCodeHandler,
  classificationCode,
  setEditMode,
  editMode,
  classificationCodeId,
  initialCode,
}): JSX.Element => {
  const [newCode, setNewCode] = useState(initialCode);
  // const clientId = useClientId();
  return (
    <TableRow>
      <TableCell>
        {!editMode ? (
          <Typography variant="body2">{classificationCode}</Typography>
        ) : (
          <TextField
            variant="outlined"
            value={newCode}
            onChange={(e) => {
              setNewCode(e.target.value);
            }}
          />
        )}
      </TableCell>
      {/*       <TableCell>
        {!editMode ? (
          <IconButton onClick={() => setEditMode(classificationCodeId)}>
            <Edit color="primary" />
          </IconButton>
        ) : (
          <ConfirmationButtons
            confirmHandler={() =>
              editCodeHandler({
                path: `/client/${clientId}/classificationCodes`,
                data: {
                  newCode,
                  oldCode: initialCode,
                },
              })
            }
            cancelHandler={() => setEditMode(null)}
            disabled={initialCode === newCode}
          />
        )}
      </TableCell>
 */}
    </TableRow>
  );
};
