import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { TextField, Button, Typography, Divider } from '@material-ui/core';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { CustomModal, LoadingIndicator } from '../../components/index';
import { ApiError } from '../../types/api_error';
import { GoogleAutocomplete } from '../../components/GoogleAutocomplete';
import { MplacePost } from '../../types/mplace';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const FormFieldWrapper = styled.div`
  margin: 20px;
  padding: 10px;
`;

interface MplaceFormProps {
  open: boolean;
  handleClose: () => void;
  clientId?: number;
  currentMplaceId?: number;
  isNew?: boolean;
  refetch: () => void;
  name?: string;
  address?: string;
  notes?: string;
}
type FormInput = {
  name: string;
  address: string;
  notes?: string;
};

interface MeasurementPlaceRequestData extends FormInput {
  lat: number;
  lng: number;
}

const typeSchema = joi.object({
  name: joi.string().required(),
  address: joi.string().required(),
  notes: joi.string().allow(''),
});

export const MplaceForm: FunctionComponent<MplaceFormProps> = ({
  open,
  handleClose,
  clientId,
  currentMplaceId,
  isNew = true,
  refetch,
  name = '',
  address = '',
  notes = '',
}) => {
  const [mplaceAddressHelper, setMplaceAddressHelper] = useState<string | undefined>(undefined);
  const { register, handleSubmit, errors, formState } = useForm<FormInput>({
    resolver: joiResolver(typeSchema),
  });

  const apiCall = isNew ? ApiService.post() : ApiService.put();
  const path = isNew ? `/client/${clientId}/mplace` : `/client/${clientId}/mplace/${currentMplaceId}`;
  const [mplaceFormSubmit, mplaceFormSubmitReqInfo] = useMutation<
    MplacePost | {},
    ApiError,
    { path: string; data: MeasurementPlaceRequestData },
    unknown
  >(apiCall);
  // eslint-disable-next-line
  const geocoder = new google.maps.Geocoder();

  const onSubmit = (data: FormInput) => {
    geocoder.geocode({ address: data.address, componentRestrictions: { country: 'hr' } }, async (results, status) => {
      if (status !== 'ZERO_RESULTS') {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        await mplaceFormSubmit({ path: path, data: { ...data, lat, lng } });
        refetch();
      } else {
        setMplaceAddressHelper('Molimo odaberite adresu iz liste');
      }
    });
  };
  const onSuccess = () => {
    refetch();
    mplaceFormSubmitReqInfo.reset();
    handleClose();
  };
  const handleFocus = () => {
    setMplaceAddressHelper(undefined);
  };

  const handleBackdropClick = () => {
    refetch();
    mplaceFormSubmitReqInfo.reset();
    handleClose();
  };

  const getModalContent = (): ReactElement => {
    if (mplaceFormSubmitReqInfo.isLoading) {
      return <LoadingIndicator />;
    }

    if (mplaceFormSubmitReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">
            {ApiService.getErrorMessage(mplaceFormSubmitReqInfo.error.response.data.code)}
          </Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={mplaceFormSubmitReqInfo.reset}>
              Pokušaj ponovo
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    if (mplaceFormSubmitReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">
            {isNew ? 'Novo mjeno mjesto dodano!' : 'Podaci o mjernom mjestu promijenjeni!'}
          </Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              U redu
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    return (
      <>
        <Typography variant="h6">
          {isNew ? 'Dodavanje novog mjernog mjesta!' : 'Mijenjanje informacija o mjernom mjestu!'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <FormFieldWrapper>
            <TextField
              fullWidth
              inputProps={{ ref: register, name: 'name' }}
              label="Ime"
              variant="outlined"
              defaultValue={name}
              error={!!errors.name}
              helperText={
                (errors.name?.type === 'string.empty' && 'Ime je obavezno') ||
                (errors.name?.type === 'string.min' && 'Minimalna duljina je 5 znakova')
              }
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <GoogleAutocomplete
              onFocus={handleFocus}
              fieldRef={register}
              id="address"
              placeholder="Unesite adresu"
              name="address"
              label="Adresa"
              defaultValue={address}
              error={!!errors.address || !!mplaceAddressHelper}
              helperText={
                (errors.address?.type === 'string.empty' ? 'Adresa je obavezna' : null) || mplaceAddressHelper
                  ? mplaceAddressHelper
                  : null
              }
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <TextField
              fullWidth
              label="Dodatne Informacije"
              variant="outlined"
              defaultValue={notes}
              inputProps={{ ref: register, name: 'notes' }}
            />
          </FormFieldWrapper>
          <Divider />
          <ButtonsWrapper>
            <Button type="submit" color="primary" disabled={!formState.isDirty}>
              Spremi
            </Button>
            <Button
              onClick={() => {
                mplaceFormSubmitReqInfo.reset();
                handleClose();
              }}
              color="default"
              type="button"
            >
              Odustani
            </Button>
          </ButtonsWrapper>
        </form>
      </>
    );
  };
  return (
    <CustomModal open={open} onClose={handleBackdropClick}>
      {getModalContent()}
    </CustomModal>
  );
};
