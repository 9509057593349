import React, { FunctionComponent } from 'react';
import { DashboardGraph } from './DashboardGraph';
import { CardsContainer } from './CardsContainer';
import { Typography, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { AllMplaces } from '../MeasurementPlaces/AllMplaces';
import { StyledDivider } from '../../components/index';
import { Suppliers } from '../shared/Suppliers';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Margin = styled.div`
  width: 100%;
  height: 25px;
`;

export const Dashboard: FunctionComponent = () => {
  return (
    <PageWrapper>
      <Typography variant="h6">Pregled potrošnje</Typography>
      <StyledDivider />
      <CardsContainer />
      <DashboardGraph />
      <Margin />
      <Paper>
        <AllMplaces isDashboardPage={true} />
      </Paper>
      <Margin />
      <Paper>
        <Suppliers isDashboard={true} />
      </Paper>
    </PageWrapper>
  );
};
