import React, { FunctionComponent, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { CustomModal, LoadingIndicator } from '../../components/index';
import { ApiError } from '../../types/api_error';
import { MeasurementPlace } from '../../types/mplace';

const Divider = styled.div`
  height: 1px;
  margin: 9.5px 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: space-between;
`;

type DeleteMplaceProps = {
  open: boolean;
  currentMplaceId: number;
  handleClose: () => void;
  clientId?: number;
  refetch: () => void;
};

export const DeleteMplace: FunctionComponent<DeleteMplaceProps> = ({
  open,
  handleClose,
  clientId,
  refetch,
  currentMplaceId,
}) => {
  const history = useHistory();
  const [deleteMplace, deleteMplaceReqInfo] = useMutation<MeasurementPlace, ApiError, { path: string }, unknown>(
    ApiService.delete()
  );

  const onSuccess = () => {
    refetch();
    deleteMplaceReqInfo.reset();
    handleClose();
    history.push(`/client/${clientId}/measurement-places`);
  };

  const handleDelete = async () => {
    await deleteMplace({
      path: `/client/${clientId}/mplace/${currentMplaceId}`,
    });
  };

  const handleBackdropClick = () => {
    refetch();
    deleteMplaceReqInfo.reset();
    handleClose();
  };

  const getModalContent = (): ReactElement => {
    if (deleteMplaceReqInfo.isLoading) {
      return <LoadingIndicator />;
    }

    if (deleteMplaceReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">
            {ApiService.getErrorMessage(deleteMplaceReqInfo.error.response.data.code)}
          </Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={deleteMplaceReqInfo.reset}>
              Pokušaj ponovno
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    if (deleteMplaceReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">Mjerno mjesto je obrisano!</Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              U redu
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    return (
      <>
        <Typography variant="body2">Jeste li sigurni da želite obrisati mjerno mjesto?</Typography>
        <Divider />
        <ButtonsWrapper>
          <Button onClick={handleDelete} color="primary">
            Potvrdi
          </Button>
          <Button onClick={handleClose} color="default" type="button">
            Odustani
          </Button>
        </ButtonsWrapper>
      </>
    );
  };
  return (
    <CustomModal open={open} onClose={handleBackdropClick}>
      {getModalContent()}
    </CustomModal>
  );
};
