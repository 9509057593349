import React, { FunctionComponent, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppRoutes } from './pages/Routes';
import { ErrorPage } from './pages/Error/ErrorPage';
import { ErrorBoundaryWithRouter } from './ErrorBoundary';
import { LoginPage } from './pages/Login/LoginPage';
import { ErrorSnackbar } from './components/ErrorSnackbar';
import { AppContext } from './context/app';
import { AuthPage } from './pages/Auth/AuthPage';
import { ChangeEmail } from './pages/ChangeEmail/ChangeEmail';
import moment from 'moment';
import 'moment/locale/hr';
moment.locale('hr');

export const App: FunctionComponent = () => {
  const { errorMessage, setErrorMessage } = useContext(AppContext);

  return (
    <ErrorBoundaryWithRouter>
      <Switch>
        <Route path="/client" component={AppRoutes} />
        <Route path="/login" component={LoginPage} />
        <Route path="/auth" component={AuthPage} />
        <Route path="/newEmail/:token" component={ChangeEmail} />
        <Route path="/error" component={ErrorPage} />
        <Redirect to="/client" />
      </Switch>
      <ErrorSnackbar
        open={!!errorMessage}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        message={errorMessage}
        autoHideDuration={2900}
      />
    </ErrorBoundaryWithRouter>
  );
};
