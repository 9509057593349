import React, { FunctionComponent, ReactElement } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useForm } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { TextField, Button, Typography, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { CustomModal } from '../../components/index';
import { ApiError } from '../../types/api_error';

const Divider = styled.div`
  height: 1px;
  margin: 9.5px 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const InfoWrapper = styled(LoadingWrapper)`
  flex-direction: column;
  justify-content: space-between;
`;

type NewClientProps = {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
};

type FormInput = {
  name: string;
  userEmail: string;
};

const typeSchema = joi.object({
  name: joi.string().required(),
  userEmail: joi
    .string()
    .required()
    .email({ tlds: { allow: false } }),
});

export const NewClient: FunctionComponent<NewClientProps> = ({ open, handleClose, refetch }) => {
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: joiResolver(typeSchema),
  });
  const query = useQueryCache();
  const clientsQuery = query.getQuery('getting-clients');

  const [addClient, addClientReqInfo] = useMutation<{}, ApiError, { path: string; data: FormInput }, unknown>(
    ApiService.post(),
    {
      onSuccess: () => {
        if (clientsQuery) {
          clientsQuery.refetch();
        }
      },
    }
  );

  const onSubmit = (data: FormInput) => {
    addClient({
      path: `/client`,
      data,
    });
  };

  const handleModalClose = () => {
    refetch();
    addClientReqInfo.reset();
    handleClose();
  };

  const onSuccess = () => {
    refetch();
    reset();
    addClientReqInfo.reset();
    handleClose();
  };

  const handleTryAgain = () => {
    addClientReqInfo.reset();
    reset();
  };

  const getModalContent = (): ReactElement => {
    if (addClientReqInfo.isLoading) {
      return (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      );
    }
    if (addClientReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">
            {ApiService.getErrorMessage(addClientReqInfo.error.response.data.code)}
          </Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={handleTryAgain}>
              Pokušaj ponovno
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }
    if (addClientReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">Klijent uspješno dodan</Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              U redu
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    return (
      <>
        <Typography variant="h6">Dodavanje novog klijenta</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <TextField
            inputProps={{ ref: register, name: 'name' }}
            label="Ime klijenta"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={errors.name ? 'Ime klijenta je obavezno' : ''}
          />
          <Divider />
          <TextField
            inputProps={{ ref: register, name: 'userEmail' }}
            label="Email adresa vlasnika"
            variant="outlined"
            fullWidth
            error={!!errors.userEmail}
            helperText={errors.userEmail ? 'Email adresa vlasnika je obavezna' : ''}
          />
          <Divider />
          <ButtonsWrapper>
            <Button type="submit" color="primary">
              Spremi
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </form>
      </>
    );
  };

  return (
    <CustomModal open={open} onClose={handleModalClose}>
      {getModalContent()}
    </CustomModal>
  );
};
