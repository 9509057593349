import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InvoiceDetails } from '../Invoice';
import { InvoiceComparison } from '../Invoice/InvoiceComparison';
import { AllMplaces } from './AllMplaces';
import { SingleMplace } from './SingleMplace';

export const MeasurementPlaces: FunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <AllMplaces />
      </Route>
      <Route path={`${path}/:mplaceId/invoice/:invoiceId`}>
        <InvoiceDetails />
      </Route>
      <Route path={`${path}/:mplaceId/invoice-comparison/:invoiceId`} component={InvoiceComparison} />
      <Route path={`${path}/:mplaceId`}>
        <SingleMplace />
      </Route>
    </Switch>
  );
};
