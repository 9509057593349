import React, { FunctionComponent } from 'react';
import { Done, Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

type ConfirmationButtonsProps = {
  cancelHandler: () => void;
  confirmHandler: () => void;
  disabled?: boolean;
};

export const ConfirmationButtons: FunctionComponent<ConfirmationButtonsProps> = ({
  cancelHandler,
  confirmHandler,
  disabled,
}) => {
  return (
    <>
      <IconButton onClick={confirmHandler} data-testid="confirm-btn" disabled={disabled}>
        <Done color={disabled ? 'disabled' : 'primary'} />
      </IconButton>
      <IconButton onClick={cancelHandler} data-testid="cancel-btn">
        <Close color="error" />
      </IconButton>
    </>
  );
};
