import React, { FunctionComponent } from 'react';
import { isEqual as _isEqual } from 'lodash';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../../theme';

const StyledValueHeader = styled(Typography)`
  opacity: 0.6;
  font-weight: 500;
`;

const StyledComparedValue = styled(({ isChanged, isOriginal, ...rest }) => <Typography {...rest} />)`
  color: ${(props) =>
    props.isChanged ? (props.isOriginal ? theme.palette.error.main : theme.palette.success.main) : ''};
  background-color: ${(props) =>
    props.isChanged ? (props.isOriginal ? theme.palette.error.main + '22' : theme.palette.success.main + '22') : ''};
  padding: 0 10px;
  margin: 5px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

interface ComparisonDetailsProps {
  data: string;
  title: string;
  dataToCompareWith: string;
  original?: boolean;
}

export const ComparisonDetails: FunctionComponent<ComparisonDetailsProps> = ({
  data,
  title,
  dataToCompareWith,
  original,
}) => {
  return (
    <>
      <StyledValueHeader variant="caption">{title}</StyledValueHeader>
      <StyledComparedValue
        variant="subtitle1"
        isChanged={!!data || !!dataToCompareWith ? !_isEqual(data, dataToCompareWith) : false}
        isOriginal={original}
      >
        {data || '-'}
      </StyledComparedValue>
    </>
  );
};
