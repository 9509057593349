import React, { FunctionComponent, ReactElement, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Typography, Button, TextField, Box } from '@material-ui/core';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { ContentWrapper, LoadingIndicator, StyledDivider } from '../../components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../context/app';

const PageWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledForm = styled.form`
  min-width: 350px;
`;

type FormInput = {
  email: string;
};

const loginSchema = joi.object({
  email: joi
    .string()
    .required()
    .email({ tlds: { allow: false } }),
});

type ChangeEmailBody = {
  path: string;
  data: {
    email: string;
    token?: string;
  };
};

type NewEmailBody = {
  path: string;
  data: {
    token?: string;
  };
};

export const ChangeEmail: FunctionComponent = () => {
  const { setErrorMessage } = useContext(AppContext);
  const { register, handleSubmit, errors } = useForm<FormInput>({
    resolver: joiResolver(loginSchema),
  });
  const { token: emailToken } = useParams<{ token?: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const verify = params.get('verify');
  const history = useHistory();

  const [changeEmail, changeEmailReqInfo] = useMutation<{}, ApiError, ChangeEmailBody, unknown>(ApiService.put(), {
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
  });
  const [newEmail] = useMutation<{}, ApiError, NewEmailBody, unknown>(ApiService.post(), {
    onSuccess: (_) => history.push('/client'),
    onError: (error) => {
      history.push('/client');
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
    },
  });

  useEffect(() => {
    if (verify) {
      newEmail({ path: '/newEmail', data: { token: emailToken } });
    }
  }, [verify, emailToken, newEmail]);

  const onSubmit = async (data: FormInput) => {
    const requestData = emailToken ? { ...data, token: emailToken } : data;
    await changeEmail({ path: '/self/email', data: requestData });
  };

  if (verify) {
    return <LoadingIndicator />;
  }

  const getContent = (): ReactElement => {
    if (changeEmailReqInfo.isLoading) {
      return <LoadingIndicator />;
    } else if (changeEmailReqInfo.isSuccess) {
      return (
        <Typography variant="body1">
          {emailToken
            ? 'Na vašu novu email adresu poslana je poveznica za potvrdu promjene!'
            : 'Na unesenu email adresu poslana je poveznica za promjenu!'}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="h6">
          {emailToken ? 'Unesite novu email adresu' : 'Unesite trenutnu email adresu'}
        </Typography>
        <Box m={2} />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            inputProps={{ ref: register, name: 'email' }}
            label="Email adresa"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email ? 'Molimo unesite valjanu email adresu' : null}
          />
          <StyledDivider />
          <Button type="submit" color="primary">
            Potvrdi
          </Button>
        </StyledForm>
      </>
    );
  };

  return <PageWrapper>{getContent()}</PageWrapper>;
};
