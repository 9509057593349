import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AllUsers } from './AllUsers';
import { SingleUser } from './SingleUser';

export const Users: FunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <AllUsers />
      </Route>
      <Route path={`${path}/:userId/`}>
        <SingleUser />
      </Route>
    </Switch>
  );
};
