import React, { FunctionComponent, ReactElement } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Badge, ListItem, ListItemText } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../theme';

const StyledListItem = styled(({ isActive, ...rest }) => <ListItem {...rest} />)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  & span {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => (props.isActive ? theme.palette.primary.main : 'rgba(33, 33, 33)')};
  }

  & svg {
    margin-right: 15px;
    fill: ${(props) => (props.isActive ? theme.palette.primary.main : 'rgba(33, 33, 33)')};
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: 40px;
  & span {
    color: white;
  }
`;

type SideMenuLinkProps = {
  navigateTo: string;
  title: string;
  icon: ReactElement;
  badgeText?: string;
};

export const SideMenuLink: FunctionComponent<SideMenuLinkProps> = ({ navigateTo, title, icon, badgeText }) => {
  const location = useLocation();
  const history = useHistory();

  const navigationHandler = () => {
    history.push(navigateTo);
  };

  return (
    <StyledListItem onClick={navigationHandler} isActive={location.pathname.includes(navigateTo)}>
      {icon}
      <ListItemText primary={title} />
      <StyledBadge
        badgeContent={badgeText}
        color="primary"
        showZero={false}
        invisible={badgeText ? badgeText === '0' : true}
      />
    </StyledListItem>
  );
};
