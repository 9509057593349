import React, { FunctionComponent, useContext, useState } from 'react';
import { useMutation, usePaginatedQuery } from 'react-query';
import { TablePaginationProps, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  StyledFloatingActionButton,
  StyledDivider,
  LoadingIndicator,
  ContentWrapper,
  SortableTable,
} from '../../components/index';
import { ApiService } from '../../services/api';
import { AppContext } from '../../context/app';
import { InvoiceType, InvoiceTypesResponse } from '../../types/invoice_type';
import { ApiError } from '../../types/api_error';
import { NewType } from './NewType';
import { useClientId } from '../Routes/useClientId';
import { TypeRow } from './TypeRow';
import { Redirect } from 'react-router-dom';

export const InvoiceTypes: FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currEditingType, setCurrEditingType] = useState<number | null>(null);
  const [currDeletingType, setCurrDeletingType] = useState<number | null>(null);
  const { setErrorMessage } = useContext(AppContext);
  const [addMode, setAddMode] = useState<boolean>(false);
  const clientId = useClientId();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('type');

  const { data, error, refetch, isLoading, isError } = usePaginatedQuery<InvoiceTypesResponse, ApiError>(
    ['invoice-types', page, rowsPerPage, clientId, sortBy, sortOrder],
    ApiService.get(`/client/${clientId}/invoice-types`, { page, rowsPerPage, sortBy, order: sortOrder })
  );

  const [editType, editTypeResult] = useMutation<
    InvoiceType,
    ApiError,
    { path: string; data: { type: string } },
    unknown
  >(ApiService.put(), {
    onError: (error) => {
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      editTypeResult.reset();
    },
  });

  const [deleteType, deleteTypeResult] = useMutation<{}, ApiError, { path: string }, unknown>(ApiService.delete(), {
    onError: (error) => {
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      deleteTypeResult.reset();
    },
  });

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setPage(newPage);
  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const mappedData = data.invoiceTypes.map((el) => (
    <TypeRow
      typeId={el.id}
      type={el.type}
      refetch={refetch}
      key={el.id}
      setEditMode={setCurrEditingType}
      setDeleteMode={setCurrDeletingType}
      editMode={currEditingType === el.id}
      deleteMode={currDeletingType === el.id}
      editType={editType}
      deleteType={deleteType}
    />
  ));

  return (
    <ContentWrapper>
      <Typography variant="h6">Tipovi</Typography>
      <StyledDivider />
      <SortableTable
        head={[
          {
            id: 'type',
            label: 'Tip',
            sortable: true,
          },
          {
            id: 'edit/delete',
            label: 'Uredi/Izbriši',
            sortable: false,
          },
        ]}
        pagination={{
          page,
          rowsPerPage,
          count: parseInt(data.count || '0'),
          onChangePage: handleChangePage,
          onChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        orderBy={sortBy}
        order={sortOrder}
        onRequestSort={(_, sortProp) => {
          setSortBy(sortProp);
          setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        }}
      >
        {mappedData}
      </SortableTable>
      <StyledFloatingActionButton onClick={() => setAddMode(true)} color="primary">
        <Add />
      </StyledFloatingActionButton>
      <NewType open={addMode} clientId={clientId} handleClose={() => setAddMode(false)} refetch={refetch} />
    </ContentWrapper>
  );
};
