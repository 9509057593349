import React, { FunctionComponent } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

interface ClientRedirectProps {
  defaultClientId: number;
}

export const ClientRedirect: FunctionComponent<ClientRedirectProps> = ({ defaultClientId }) => {
  const { url } = useRouteMatch();
  let clientId: string | number | null = localStorage.getItem('clientId');
  if (!clientId) {
    clientId = defaultClientId;
  }
  return <Redirect to={`${url}/${clientId}`} />;
};
