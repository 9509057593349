import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { theme } from './theme';
import * as serviceWorker from './serviceWorker';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './context/app';
import './styles.css';

const queryCache = new QueryCache();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AppContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </ReactQueryCacheProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
