import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, IconButton, Link as MUILink, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

interface InvoicesTableRowProps {
  orderNumber: number;
  customer: string;
  amount: number;
  period: string;
  downloadLink?: string;
  routePath?: string;
  id: number;
}

export const InvoicesTableRow: FunctionComponent<InvoicesTableRowProps> = ({
  orderNumber,
  customer,
  amount,
  period,
  downloadLink,
  routePath,
  id,
}) => {
  return (
    <TableRow>
      <TableCell>{orderNumber}</TableCell>
      <TableCell>{customer}</TableCell>
      <TableCell>{amount} kn</TableCell>
      <TableCell>{period}</TableCell>
      <TableCell>
        {downloadLink ? (
          <a href={downloadLink} download>
            <IconButton>
              <GetApp />
            </IconButton>
          </a>
        ) : (
          <MUILink component={Link} to={`${routePath}/${id}`} key={id}>
            <Typography variant="button">Detalji</Typography>
          </MUILink>
        )}
      </TableCell>
    </TableRow>
  );
};
