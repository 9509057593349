import React, { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { isEqual } from 'lodash';

interface Option {
  label: string;
  id: string | number;
}

interface ControlledAutocompleteProps
  extends AutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined> {
  name: string;
  options: Option[];
  control: Control;
}

export const ControlledAutocomplete: FunctionComponent<ControlledAutocompleteProps> = ({
  name,
  options,
  control,
  renderInput,
  ...rest
}) => {
  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...rest}
          {...props}
          options={options}
          getOptionLabel={(option) => {
            if (option === null) {
              return '';
            }
            if (!option.label) {
              const selected = options.find((o) => o.id === option);
              return selected?.label || '';
            }
            return option.label;
          }}
          renderOption={(option) => <span>{option.label}</span>}
          getOptionSelected={(option, value) => {
            if (value === null) {
              return true;
            }
            if (!value.id) {
              const selected = options.find((o) => Number(o.id) === Number(value));
              return isEqual(option, selected);
            }
            return isEqual(option, value);
          }}
          renderInput={renderInput}
          onChange={(_, data) => {
            if (data === null) {
              return;
            }
            if (!data.id) {
              return props.onChange(data);
            }
            return props.onChange(data.id);
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
};
