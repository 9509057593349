import React, { FunctionComponent, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PapperWrapper = styled(Paper)`
  width: 49.33%;
  padding: 15px;
  margin-top: 50px;
`;

interface GoogleMapProps {
  lat: number;
  lng: number;
  title: string;
}

export const GoogleMap: FunctionComponent<GoogleMapProps> = ({ lat, lng, title }) => {
  useEffect(() => {
    // eslint-disable-next-line
    const map = new google.maps.Map(document.getElementById('map') as HTMLDivElement, {
      center: { lat: lat, lng: lng },
      zoom: 15,
      streetViewControl: false,
    });
    // eslint-disable-next-line
    new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map,
      title: title,
    });
  }, [lat, lng, title]);
  return (
    <PapperWrapper>
      <MapWrapper id="map" />
    </PapperWrapper>
  );
};
