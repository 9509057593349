import React, { FunctionComponent, useState } from 'react';
import { TableRow, TableCell, IconButton, TextField } from '@material-ui/core';
import { Edit, DeleteForever } from '@material-ui/icons';
import { ConfirmationButtons } from '../../components/index';

type ClientRowProps = {
  clientId: number;
  clientName: string;
  number: number;
  editMode: boolean;
  deleteMode: boolean;
  setEditMode: (id: number | null) => void;
  setDeleteMode: (id: number | null) => void;
  editClient: (name: string) => void;
  deleteClient: () => void;
};

export const ClientRow: FunctionComponent<ClientRowProps> = ({
  clientId,
  clientName,
  number,
  editMode,
  deleteMode,
  setEditMode,
  setDeleteMode,
  editClient,
  deleteClient,
}) => {
  const [currentClientName, setCurrentClientName] = useState<string>(clientName);

  return (
    <TableRow>
      <TableCell>{number}</TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            value={currentClientName}
            onChange={(e) => {
              setCurrentClientName(e.target.value);
            }}
            variant="outlined"
          />
        ) : (
          clientName
        )}
      </TableCell>
      <TableCell>
        {!editMode ? (
          <IconButton onClick={() => setEditMode(clientId)} disabled={deleteMode}>
            <Edit color={deleteMode ? 'disabled' : 'primary'} />
          </IconButton>
        ) : (
          <ConfirmationButtons
            confirmHandler={() => editClient(currentClientName)}
            cancelHandler={() => setEditMode(null)}
            disabled={clientName === currentClientName}
          />
        )}
        {!deleteMode ? (
          <IconButton onClick={() => setDeleteMode(clientId)} disabled={editMode}>
            <DeleteForever color={editMode ? 'disabled' : 'error'} />
          </IconButton>
        ) : (
          <ConfirmationButtons confirmHandler={deleteClient} cancelHandler={() => setDeleteMode(null)} />
        )}
      </TableCell>
    </TableRow>
  );
};
