export const commonInvoicesProperties = [
  'supplierName',
  'customerId',
  'customerName',
  'amount',
  'issuedAt',
  'startDate',
  'endDate',
  'month',
  'year',
  'notes',
];

export const invoicePropsMapper: { [key: string]: string } = {
  supplierId: 'ID dostavljača',
  customerId: 'ID kupca',
  customerName: 'Ime kupca',
  amount: 'Iznos',
  issuedAt: 'Datum izdavanja',
  startDate: 'Datum početka',
  endDate: 'Datum završetka',
  month: 'Mjesec',
  year: 'Godina',
  notes: 'Bilješke',
  name: 'Ime',
  description: 'Opis',
  priceAmount: 'Iznos',
  unitAmount: 'Iznos u mjernim jedinicama',
  unitCode: 'Mjerna jedinica',
  classificationCode: 'Klasifikacijski kod',
  supplierName: 'Dobavljač',
};
