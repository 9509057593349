import React, { FunctionComponent } from 'react';
import { Modal, ModalProps } from '@material-ui/core';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  padding: 30px;
  background-color: #fff;
  outline: 0;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
`;

export const CustomModal: FunctionComponent<ModalProps> = ({ open, children, onClose }) => {
  return (
    <Modal open={open} data-testid="custom-modal" onClose={onClose}>
      <ModalWrapper>{children}</ModalWrapper>
    </Modal>
  );
};
