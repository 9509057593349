import React, { FunctionComponent, useContext } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from '@material-ui/core';
import { get, omit } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { InvoiceAdditionalItem } from '../../types/invoice';
import joi from '@hapi/joi';
import moment from 'moment';
import { useMutation, useQueryCache } from 'react-query';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { ControlledAutocomplete } from '../../components/ControlledAutocomplete';
import { MeasurementPlace } from '../../types/mplace';
import { InvoiceType } from '../../types/invoice_type';
import { Supplier } from '../../types/supplier';
import { AdditionalItem, FieldsWrapper, StyledTextField } from './AdditionalItem';
import { AppContext } from '../../context/app';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator, StyledDivider } from '../../components';

export interface InvoiceData {
  supplierId?: number;
  customerId?: number;
  customerName: string;
  amount: number;
  issuedAt: Date;
  startDate?: Date | null;
  endDate?: Date | null;
  notes?: string;
  additionalItems: InvoiceAdditionalItem[];
  typeId?: number;
  mplaceId?: number;
}

interface InvoiceRequestData extends InvoiceData {
  year: number;
  month: number;
  invoiceOriginalId?: number | null;
  typeIds: (number | undefined)[];
}

interface InviceFormProps {
  defaultValues: InvoiceData;
  invoiceId?: number;
  invoiceOriginalId: number | null;
  clientId: number;
  mplaces: MeasurementPlace[];
  invoiceTypes: InvoiceType[];
  mplaceId: string;
  suppliers: Supplier[];
}

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`;

const invoiceSchema = joi.object({
  mplaceId: joi.number().required(),
  typeId: joi.number().required(),
  supplierId: joi.number().allow('', null),
  customerId: joi.string().allow('', null),
  customerName: joi.string().required(),
  amount: joi.number().required().positive(),
  issuedAt: joi
    .date()
    .required()
    .max(new Date(new Date().getFullYear() + 1, 12, 31)),
  startDate: joi
    .date()
    .allow(null, '')
    .max(new Date(new Date().getFullYear() + 1, 12, 31)),
  endDate: joi
    .date()
    .allow(null, '')
    .max(new Date(new Date().getFullYear() + 1, 12, 31)),
  notes: joi.string().allow('', null),
  additionalItems: joi.array().items(
    joi.object({
      name: joi.string().required(),
      description: joi.string().required(),
      priceAmount: joi.number().required().positive(),
      unitAmount: joi.number().required().positive(),
      unitCode: joi.string().allow('', null),
      classificationCode: joi.string().allow('', null),
    })
  ),
});

export const InvoiceForm: FunctionComponent<InviceFormProps> = ({
  defaultValues,
  invoiceId,
  clientId,
  invoiceOriginalId,
  mplaces,
  invoiceTypes,
  mplaceId,
  suppliers,
}) => {
  const { setErrorMessage } = useContext(AppContext);
  const history = useHistory();
  const isUpdate = !!invoiceId;
  const ApiCall = isUpdate ? ApiService.put() : ApiService.post();
  const queryCache = useQueryCache();
  const onSuccess = () => {
    const query = queryCache.getQuery('drafts-count');
    if (query) {
      query.refetch();
    }
    history.push(`/client/${clientId}/dashboard`);
  };

  const [apiCreate, { isLoading, reset }] = useMutation<
    object,
    ApiError,
    { path: string; data: InvoiceRequestData },
    unknown
  >(ApiCall, {
    onSuccess,
    onError: (error) => {
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      reset();
    },
  });
  const { register, handleSubmit, errors, control, formState } = useForm<InvoiceData>({
    resolver: joiResolver(invoiceSchema),
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray<InvoiceAdditionalItem>({
    control,
    name: 'additionalItems',
  });

  const onSubmit = ({ mplaceId, typeId, startDate, endDate, issuedAt, ...rest }: InvoiceData) => {
    const path = `/client/${clientId}/mplace/${mplaceId}/invoice${isUpdate ? `/${invoiceId}` : ''}`;

    const data: InvoiceRequestData = {
      ...rest,
      startDate: startDate || null,
      endDate: endDate || null,
      issuedAt: issuedAt,
      year: moment(issuedAt).get('year'),
      month: moment(issuedAt).get('month') + 1,
      additionalItems: get(rest, 'additionalItems', []),
      typeIds: [typeId],
      invoiceOriginalId: invoiceOriginalId || null,
    };

    apiCreate({ path, data: isUpdate ? omit(data, ['invoiceOriginalId']) : data });
  };

  const appendAdditional = () =>
    append({
      name: '',
      description: '',
      priceAmount: undefined,
      unitAmount: undefined,
      unitCode: '',
    });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FieldsWrapper>
        <ControlledAutocomplete
          fullWidth
          hidden={!!mplaceId}
          control={control}
          name="mplaceId"
          options={mplaces.map((el) => ({ id: el.id, label: el.name }))}
          noOptionsText="Nema rezultata"
          renderInput={(params) => {
            return (
              <StyledTextField
                {...params}
                label="Mjerno mjesto"
                variant="outlined"
                helperText={errors.mplaceId ? 'Mjerno mjesto je obavezno' : ''}
                error={!!errors.mplaceId}
              />
            );
          }}
        />

        <ControlledAutocomplete
          fullWidth
          control={control}
          name="typeId"
          options={invoiceTypes.map((el) => ({ id: el.id, label: el.type }))}
          noOptionsText="Nema rezultata"
          renderInput={(params) => {
            return (
              <StyledTextField
                {...params}
                label="Tip računa"
                variant="outlined"
                helperText={errors.typeId ? 'Tip računa je obavezan' : ''}
                error={!!errors.typeId}
              />
            );
          }}
        />
        <ControlledAutocomplete
          fullWidth
          control={control}
          name="supplierId"
          options={suppliers.map((el) => ({ id: el.id, label: el.supplierName }))}
          noOptionsText="Nema rezultata"
          renderInput={(params) => {
            return <StyledTextField {...params} label="Dobavljač" variant="outlined" />;
          }}
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'customerId' }}
          label="ID korisnika"
          variant="outlined"
          fullWidth
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'customerName' }}
          label="Ime korisnika"
          variant="outlined"
          fullWidth
          error={!!errors.customerName}
          helperText={errors.customerName ? 'Ime korisnika je obavezno' : ''}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <StyledTextField
          inputProps={{ ref: register, name: 'amount', type: 'number', step: '0.01' }}
          label="Iznos u kunama"
          variant="outlined"
          fullWidth
          error={!!errors.amount}
          helperText={errors.amount ? 'Iznos je obavezan' : ''}
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'issuedAt', type: 'date' }}
          label="Datum izdavanja"
          variant="outlined"
          fullWidth
          error={!!errors.issuedAt}
          helperText={errors.issuedAt ? 'Datum izdavanja obavezan' : ''}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'startDate', type: 'date', max: new Date() }}
          label="Datum početka"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'endDate', type: 'date', max: new Date() }}
          label="Datum završetka"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <StyledTextField
          inputProps={{ ref: register, name: 'notes' }}
          label="Bilješke"
          variant="outlined"
          fullWidth
          multiline
        />
      </FieldsWrapper>
      {fields.map((item, index) => {
        return (
          <AdditionalItem
            defaultValues={item}
            reference={register}
            errors={errors}
            removeItem={() => remove(index)}
            index={index}
            key={item.id}
          />
        );
      })}
      <ButtonsWrapper>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Button onClick={appendAdditional} variant="outlined" color="secondary">
              Dodaj dodatnu stavku
            </Button>
            <StyledDivider />
            <Button variant="contained" type="submit" color="primary" fullWidth disabled={!formState.isDirty}>
              {invoiceId ? 'Ažuriraj račun' : 'Spremi račun'}
            </Button>
          </>
        )}
      </ButtonsWrapper>
    </StyledForm>
  );
};
