import React, { FunctionComponent, useState } from 'react';
import { usePaginatedQuery, useQuery } from 'react-query';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Paper, TablePaginationProps, Typography } from '@material-ui/core';
import { ContentWrapper, LoadingIndicator, StyledDivider, SortableTable } from '../../components/index';
import { ApiService } from '../../services/api';
import { useClientId } from '../Routes/useClientId';
import { OriginalInvoicesResponse } from '../../types/invoice';
import { MeasurementPlace } from '../../types/mplace';
import { ApiError } from '../../types/api_error';
import { InvoicesTableRow } from '../shared/InvoicesTableRow';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FilterInput } from '../shared/InvoicesFilterInput';

const DateInputsWrapper = styled.div`
  margin: 10px 0;
  width: fit-content;
  display: flex;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const DatePickerWrapper = styled.div`
  margin-right: 10px;
`;

export const OriginalInvoices: FunctionComponent = () => {
  const now = moment();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const clientId = useClientId();
  const [startDate, setStartDate] = useState<string | undefined>(now.startOf('year').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string | undefined>(now.endOf('year').format('YYYY-MM-DD'));
  const [mplacesFilter, setMplacesFilter] = useState<number[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState<string | number>('month');

  const { data, error, isLoading, isError } = usePaginatedQuery<OriginalInvoicesResponse, ApiError>(
    ['original-invoices', page, rowsPerPage, clientId, startDate, endDate, mplacesFilter, sortOrder, sortBy],
    ApiService.get(`/client/${clientId}/original-invoices`, {
      page,
      rowsPerPage,
      startDate,
      endDate,
      mplaceIds: mplacesFilter,
      sortBy,
      order: sortOrder,
    })
  );
  const {
    data: mplacesData,
    isError: mplacesIsError,
    isLoading: mplacesIsLoading,
    error: mplacesError,
    refetch: mplacesRefetch,
  } = useQuery<MeasurementPlace[], ApiError>(['mplaces-filter'], ApiService.get(`/client/${clientId}/client-mplaces`));

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setPage(newPage);
  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const mappedData = data.originalInvoices.map((originalInvoice, i) => (
    <InvoicesTableRow
      key={originalInvoice.id}
      orderNumber={i + 1}
      customer={originalInvoice.mplaceName}
      period={`${originalInvoice.month}/${originalInvoice.year}`}
      amount={originalInvoice.amount}
      downloadLink={originalInvoice.fileLink}
      id={originalInvoice.id}
    />
  ));

  return (
    <ContentWrapper>
      <Typography variant="h6">Prenesni računi</Typography>
      <Typography variant="subtitle1">
        U tablici su prikazani svi preneseni računi za odabrani period. Ukoliko niste odabrali period prikazuju se
        računi za tekuću godinu.
      </Typography>
      <StyledDivider />
      <Paper>
        <TableHeaderWrapper>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DateInputsWrapper>
              <DatePickerWrapper>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  openTo="year"
                  views={['year', 'month']}
                  label="Od"
                  value={startDate}
                  maxDate={moment().endOf('month').format('YYYY-MM-DD')}
                  onChange={(date) => setStartDate(date?.format('YYYY-MM-01'))}
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  openTo="year"
                  views={['year', 'month']}
                  label="Do"
                  value={endDate}
                  minDate={startDate}
                  maxDate={moment().endOf('year').format('YYYY-MM-DD')}
                  onChange={(date) => setEndDate(date?.format('YYYY-MM-DD'))}
                />
              </DatePickerWrapper>
            </DateInputsWrapper>
          </MuiPickersUtilsProvider>
          <FilterInput
            isLoading={mplacesIsLoading}
            isError={mplacesIsError}
            data={mplacesData}
            error={mplacesError}
            refetch={mplacesRefetch}
            label="Filtrirajte prenesene račune po mjernom mjestu"
            placeholder="Mjerna mjesta"
            stateSetter={setMplacesFilter}
          />
        </TableHeaderWrapper>
        <SortableTable
          head={[
            {
              id: 'order_number',
              label: 'Redni broj',
              sortable: false,
            },
            {
              id: 'mplace_name',
              label: 'Mjerno mjesto',
              sortable: true,
            },
            {
              id: 'amount',
              label: 'Iznos',
              sortable: true,
            },
            {
              id: 'month',
              label: 'Period',
              sortable: true,
            },
            {
              id: 'download',
              label: 'Preuzimanje',
              sortable: false,
            },
          ]}
          pagination={{
            page,
            rowsPerPage,
            count: parseInt(data.count || '0'),
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          orderBy={sortBy}
          order={sortOrder}
          onRequestSort={(_, sortProp) => {
            setSortBy(sortProp);
            setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        >
          {mappedData}
        </SortableTable>
      </Paper>
    </ContentWrapper>
  );
};
