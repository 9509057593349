import { Button, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { FunctionComponent } from 'react';
import { UseFormMethods, ArrayField } from 'react-hook-form';
import styled from 'styled-components';
import { StyledDivider } from '../../components';
import { InvoiceAdditionalItem } from '../../types/invoice';

const RemoveAdditionalFieldBtn = styled(Button)`
  align-self: flex-end;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  box-sizing: border-box;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const StyledTextField = styled(TextField)`
  margin: 10px 0;
`;

interface AdditionalItemProps {
  reference: UseFormMethods['register'];
  defaultValues: Partial<ArrayField<InvoiceAdditionalItem, 'id'>>;
  errors: object;
  index: number;
  removeItem: () => void;
}

export const AdditionalItem: FunctionComponent<AdditionalItemProps> = ({
  reference,
  defaultValues,
  errors,
  removeItem,
  index,
}) => {
  return (
    <FieldsWrapper>
      <StyledDivider />
      <Typography variant="caption">Dodatna stavka {index + 1}</Typography>
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].priceAmount`, type: 'number', step: '0.01' }}
        label="Iznos u kunama"
        variant="outlined"
        fullWidth
        error={!!get(errors, `additionalItems[${index}].priceAmount`)}
        helperText={get(errors, `additionalItems[${index}].priceAmount`) ? 'Iznos je obavezan' : ''}
        defaultValue={defaultValues.priceAmount}
      />
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].unitAmount`, type: 'number', step: '0.01' }}
        label="Iznos u mjernim jedinicama"
        variant="outlined"
        fullWidth
        error={!!get(errors, `additionalItems[${index}].unitAmount`)}
        helperText={get(errors, `additionalItems[${index}].unitAmount`) ? 'Iznos je obavezan' : ''}
        defaultValue={defaultValues.unitAmount}
      />
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].unitCode` }}
        label="Mjerna jedinica"
        variant="outlined"
        fullWidth
        defaultValue={defaultValues.unitCode}
      />
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].description` }}
        label="Opis"
        variant="outlined"
        fullWidth
        error={!!get(errors, `additionalItems[${index}].description`)}
        helperText={get(errors, `additionalItems[${index}].description`) ? 'Opis je obavezan' : ''}
        defaultValue={defaultValues.description}
      />
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].name` }}
        label="Ime"
        variant="outlined"
        fullWidth
        error={!!get(errors, `additionalItems[${index}].name`)}
        helperText={get(errors, `additionalItems[${index}].name`) ? 'Ime je obavezno' : ''}
        defaultValue={defaultValues.name}
      />
      <StyledTextField
        inputProps={{ ref: reference(), name: `additionalItems[${index}].classificationCode` }}
        label="Klasifikacijski kod"
        variant="outlined"
        fullWidth
        defaultValue={defaultValues.classificationCode}
      />
      <RemoveAdditionalFieldBtn onClick={removeItem} variant="outlined" color="secondary">
        Ukloni dodatnu stavku
      </RemoveAdditionalFieldBtn>
    </FieldsWrapper>
  );
};
