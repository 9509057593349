import React, { FunctionComponent, useContext, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Dashboard } from '../Dashboard/index';
import { InvoiceTypes } from '../InvoiceTypes/InvoiceTypes';
import { MeasurementPlaces } from '../MeasurementPlaces/index';
import { Users } from '../Users/index';
import { UploadInvoice, NewInvoice } from '../Invoice/index';
import styled from 'styled-components';
import { Header } from '../../components/Header';
import { DropdownItem } from '../../components/Dropdown';
import { SideMenu } from '../../components/SideMenu';
import { useClientId } from './useClientId';
import { Client } from '../../types/client';
import { AppContext } from '../../context/app';
import { AllClients } from '../Superadmin/index';
import { ApiService } from '../../services/api';
import { ChangeEmail } from '../ChangeEmail/ChangeEmail';
import { ClassificationCodes } from '../ClassificationCodes';
import { OriginalInvoices } from '../OriginalInvoices/index';
import { SupplierDetails } from '../Supplier/SupplierDetails';
import { Suppliers } from '../shared/Suppliers';

const ViewWrapper = styled.div`
  padding: 100px 20px 20px 20px;
  flex: 1;
  display: flex;

  @media screen and (max-width: 1025px) {
    padding: 80px 10px 10px 10px;
  }

  .MuiDrawer-paper {
    width: 100%;
  }
`;

type UserClientRole = {
  [key: number]: number;
};

type ClientRoutesProps = {
  clients: Client[];
};

export const ClientRoutes: FunctionComponent<ClientRoutesProps> = ({ clients }) => {
  const { isSuperadmin, setErrorMessage } = useContext(AppContext);
  const { path } = useRouteMatch();
  const [drawerOpen, setDrawerOpen] = useState(window.innerWidth > 1025);
  const clientId = useClientId();

  const clientRoles: UserClientRole = {};
  const clientItems: DropdownItem[] = clients.map((el: Client) => {
    clientRoles[el.id] = el.userRole;
    return {
      value: el.id,
      displayValue: el.name,
    };
  });

  if (!clients.find((el) => el.id === clientId)) {
    setErrorMessage(ApiService.getErrorMessage(4002));
    return <Redirect to="/client" />;
  }

  return (
    <ViewWrapper>
      <Header clients={clientItems} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} currentClientId={clientId} />
      <SideMenu
        userClientRoles={clientRoles}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        clientId={clientId}
      />

      <Switch>
        <Route path={`${path}/dashboard`} component={Dashboard} />
        <Route path={`${path}/measurement-places`} component={MeasurementPlaces} />
        <Route path={`${path}/suppliers`} component={Suppliers} />
        <Route path={`${path}/supplier/:supplierId`} component={SupplierDetails} />
        {(clientRoles[clientId] >= 10 || isSuperadmin) && (
          <Route path={`${path}/upload-invoice`} component={UploadInvoice} />
        )}
        {(clientRoles[clientId] >= 10 || isSuperadmin) && (
          <Route path={`${path}/uploaded-invoices`} component={OriginalInvoices} />
        )}
        {(clientRoles[clientId] >= 10 || isSuperadmin) && (
          <Route path={`${path}/create-invoice`} component={NewInvoice} />
        )}
        {(clientRoles[clientId] >= 20 || isSuperadmin) && (
          <Route path={`${path}/invoice-types`} component={InvoiceTypes} />
        )}
        {(clientRoles[clientId] >= 20 || isSuperadmin) && (
          <Route path={`${path}/classificationCodes`} component={ClassificationCodes} />
        )}
        {(clientRoles[clientId] >= 20 || isSuperadmin) && <Route path={`${path}/users`} component={Users} />}
        <Route path={`${path}/email/`} component={ChangeEmail} />
        {isSuperadmin && <Route path={`${path}/clients`} component={AllClients} />}
        <Redirect to={`${path}/dashboard`} />
      </Switch>
    </ViewWrapper>
  );
};
