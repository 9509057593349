import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { Typography, Paper } from '@material-ui/core';
import { useLocation, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { get } from 'lodash';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { InvoiceTypesResponse } from '../../types/invoice_type';
import { Supplier } from '../../types/supplier';
import { MeasurementPlacesResponse } from '../../types/mplace';
import { useClientId } from '../Routes/useClientId';
import { InvoiceData, InvoiceForm } from './InvoiceForm';
import { LoadingIndicator, StyledDivider } from '../../components';

interface HistoryLocationState {
  invoice: object;
  mplaceId: number;
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewInvoice: FunctionComponent = () => {
  const clientId = useClientId();
  const location = useLocation<HistoryLocationState>();

  const { data: mplacesData, error: mplacesError, isLoading: mplacesIsLoading } = useQuery<
    MeasurementPlacesResponse,
    ApiError
  >(['invoice-mplaces', clientId], ApiService.get(`/client/${clientId}/mplace`, { page: 0, rowsPerPage: 1000 }));
  const { data: typesData, error: typesError, isLoading: typesIsLoading } = useQuery<InvoiceTypesResponse, ApiError>(
    ['invoice-types', clientId],
    ApiService.get(`/client/${clientId}/invoice-types`, { page: 0, rowsPerPage: 1000 })
  );
  const { data: suppliersData, error: suppliersError, isLoading: suppliersIsLoading } = useQuery<Supplier[], ApiError>(
    ['client-suppliers', clientId],
    ApiService.get(`/client/${clientId}/suppliers`)
  );

  if (mplacesIsLoading || typesIsLoading || suppliersIsLoading) {
    return <LoadingIndicator />;
  }

  if (mplacesError || typesError || suppliersError || !mplacesData || !typesData || !suppliersData) {
    const error = mplacesError || typesError;
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const invoiceId: number | undefined = get(location, 'state.invoice.id');
  const invoiceOriginalId: number | null = get(location, 'state.invoice.invoiceOriginalId', null);
  const mplaceId = get(location, 'state.invoice.mplaceId', null);
  const typeName = get(location, 'state.invoice.types[0]', null);

  const defaultValues: InvoiceData = {
    supplierId: get(location, 'state.invoice.supplierId'),
    customerId: get(location, 'state.invoice.customerId'),
    customerName: get(location, 'state.invoice.customerName'),
    amount: get(location, 'state.invoice.amount'),
    issuedAt: get(location, 'state.invoice.issuedAt'),
    startDate: get(location, 'state.invoice.startDate'),
    endDate: get(location, 'state.invoice.endDate'),
    notes: get(location, 'state.invoice.notes'),
    additionalItems: get(location, 'state.invoice.additionalItems'),
    mplaceId,
    typeId: typeName,
  };

  if (typeName !== null) {
    const type = typesData.invoiceTypes.find((t) => t.type.toLowerCase() === typeName.toLowerCase());
    if (type) {
      defaultValues.typeId = type.id;
    }
  }

  return (
    <PageWrapper>
      <Typography variant="h6">Unos računa</Typography>
      <StyledDivider />
      <StyledPaper>
        <InvoiceForm
          invoiceTypes={typesData.invoiceTypes}
          mplaces={mplacesData.mplaces}
          mplaceId={mplaceId}
          defaultValues={defaultValues}
          invoiceOriginalId={invoiceOriginalId}
          invoiceId={invoiceId}
          clientId={clientId}
          suppliers={suppliersData}
        />
      </StyledPaper>
    </PageWrapper>
  );
};
