import React, { FunctionComponent } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export interface DropdownItem {
  value: number;
  displayValue: string;
}

type DropdownProps = {
  items: DropdownItem[];
  changeHandler: (id: number) => void;
  labelText: string;
  selectedValue?: number;
  variant?: 'standard' | 'outlined' | 'filled';
  inputProps?: object;
  native?: boolean;
};

const LabelWrapper = styled.div`
  & .MuiInputLabel-formControl {
    margin-left: 13px;
  }
`;

export const Dropdown: FunctionComponent<DropdownProps> = ({
  items,
  changeHandler,
  labelText,
  selectedValue,
  variant,
  inputProps,
  native,
}) => {
  const onChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    changeHandler(event.target.value as number);
  };
  return (
    <FormControl fullWidth>
      {variant === 'outlined' ? (
        <LabelWrapper>
          <InputLabel id={labelText}>{labelText}</InputLabel>
        </LabelWrapper>
      ) : (
        <InputLabel id={labelText}>{labelText}</InputLabel>
      )}
      <Select
        labelId={labelText}
        data-testid={labelText}
        value={selectedValue}
        onChange={onChange}
        fullWidth
        inputProps={inputProps}
        native={native}
        variant={variant || 'standard'}
      >
        {!native
          ? items.map((el) => (
              <MenuItem value={el.value} key={el.value}>
                {el.displayValue}
              </MenuItem>
            ))
          : items.map((el) => (
              <option value={el.value} key={el.value}>
                {el.displayValue}
              </option>
            ))}
      </Select>
    </FormControl>
  );
};
