import React, { FunctionComponent } from 'react';
import { ConsumptionCardContainer } from './ConsumptionCard';
import styled from 'styled-components';
import moment, { unitOfTime } from 'moment';
import { useQuery } from 'react-query';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { GraphResponse } from '../../types/dashboard_data';
import { useClientId } from '../Routes/useClientId';

const CardsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const percentageCalculation = (newValue?: number, originalValue?: number) => {
  if (newValue && originalValue) {
    const percentage = (newValue / originalValue - 1) * 100;
    return parseFloat(percentage.toFixed(2));
  }
};

const getMostExpensiveMonth = (graphResponse?: GraphResponse) => {
  if (graphResponse?.calculatedData) {
    const arrayOfValues = Object.keys(graphResponse.calculatedData).map(
      (key) => graphResponse.calculatedData[key].total
    );
    const maxAmount = Math.max(...arrayOfValues);
    const month =
      Object.keys(graphResponse.calculatedData).find((key) => graphResponse.calculatedData[key].total === maxAmount) ||
      '--/--';
    return { month, amount: maxAmount > 0 ? maxAmount.toFixed(2) : '0' };
  }
  return { month: '--/--', amount: '0' };
};

const getFormattedDate = (
  startOf: unitOfTime.StartOf,
  subtractMonths: number,
  subtractYears: number,
  format = "YYYY-MM-DD'"
) => {
  return moment().subtract(subtractYears, 'year').subtract(subtractMonths, 'month').startOf(startOf).format(format);
};

export const CardsContainer: FunctionComponent<{ mplaceId?: string }> = ({ mplaceId }) => {
  const clientId = useClientId();

  const { data: yearlyCost, error: yearlyCostError, isLoading: yearlyIsLoading } = useQuery<GraphResponse, ApiError>(
    mplaceId ? `yearly-cost-${clientId}-${mplaceId}` : `yearly-cost-${clientId}`,
    ApiService.get(`/client/${clientId}/invoices/consumption`, {
      startDate: getFormattedDate('year', 0, 0),
      endDate: moment().format('YYYY-MM-DD'),
      mplaceId,
    })
  );

  const { data: monthCost, error: monthCostError, isLoading: monthCostIsLoading } = useQuery<GraphResponse, ApiError>(
    mplaceId ? `last-month-cost-${clientId}-${mplaceId}` : `last-month-cost-${clientId}`,
    ApiService.get(`/client/${clientId}/invoices/consumption`, {
      startDate: getFormattedDate('month', 1, 0),
      endDate: getFormattedDate('month', 0, 0),
      mplaceId,
    })
  );

  const { data: lastMonthCost, error: lastMonthCostError, isLoading: lastMonthCostIsLoading } = useQuery<
    GraphResponse,
    ApiError
  >(
    mplaceId ? `last-year-month-cost-${clientId}-${mplaceId}` : `last-year-month-cost-${clientId}`,
    ApiService.get(`/client/${clientId}/invoices/consumption`, {
      startDate: getFormattedDate('month', 2, 0),
      endDate: getFormattedDate('month', 1, 0),
      mplaceId,
    })
  );

  return (
    <CardsRow>
      <ConsumptionCardContainer
        error={yearlyCostError}
        isLoading={yearlyIsLoading}
        title="Godišnja potrošnja"
        badgeText={getFormattedDate('year', 0, 0, 'YYYY')}
        amount={`${yearlyCost?.total} kn`}
      />
      <ConsumptionCardContainer
        error={monthCostError || lastMonthCostError}
        isLoading={monthCostIsLoading || lastMonthCostIsLoading}
        percentage={percentageCalculation(monthCost?.total, lastMonthCost?.total)}
        title="Mjesečna potrošnja"
        badgeText={getFormattedDate('month', 1, 0, 'MM/YYYY')}
        amount={`${monthCost?.total} kn`}
      />
      <ConsumptionCardContainer
        error={yearlyCostError}
        isLoading={yearlyIsLoading}
        title="Mjesec s najvećom potrošnjom"
        badgeText={getMostExpensiveMonth(yearlyCost).month}
        amount={`${getMostExpensiveMonth(yearlyCost).amount} kn`}
      />
      <ConsumptionCardContainer
        error={yearlyCostError}
        isLoading={yearlyIsLoading}
        title="Broj računa"
        badgeText={getFormattedDate('year', 0, 0, 'YYYY')}
        amount={`${yearlyCost?.invoicesCount}`}
      />
    </CardsRow>
  );
};
