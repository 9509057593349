import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, usePaginatedQuery } from 'react-query';
import { MeasurementPlaceSingle } from '../../types/mplace';
import { InvoiceResponse, OriginalInvoicesResponse } from '../../types/invoice';
import { MplaceForm } from './MplaceForm';
import { ApiService } from '../../services/api';
import { Grid, Typography, Button, TablePaginationProps, Paper } from '@material-ui/core';
import { Edit, Add, DeleteForever } from '@material-ui/icons';
import {
  GoogleMap,
  LoadingIndicator,
  StyledButton,
  StyledDivider,
  StyledFloatingActionButton,
  ContentWrapper,
} from '../../components/index';
import styled from 'styled-components';
import { DeleteMplace } from './DeleteMplace';
import { InvoiceTypesResponse } from '../../types/invoice_type';
import { ApiError } from '../../types/api_error';
import { useClientId } from '../Routes/useClientId';
import { DashboardGraph } from '../Dashboard/DashboardGraph';
import { CardsContainer } from '../Dashboard/CardsContainer';
import { AppContext } from '../../context/app';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MplaceInvoicesTableContent } from './MplaceInvoicesTableContent';
import { FilterInput } from '../shared/InvoicesFilterInput';

const ErrorWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  height: fit-content;
  justify-content: space-between;
`;

const FlexItemWrapper = styled.div`
  height: fit-content;
  width: 49.33%;
`;

const ButtonRowWrapper = styled.div`
  display: flex;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-around;
  margin: 15px 10px;
`;

const MarginWrapper = styled.div`
  margin: 20px 0;
`;

const PageHeaderButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 10px;
`;

const StyledPageHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`;

const DateInputsWrapper = styled.div`
  margin: 10px 0;
  width: fit-content;
  display: flex;
`;

const DatePickerWrapper = styled.div`
  margin: 0 10px;
`;

interface RouteParams {
  mplaceId: string;
}

export const SingleMplace: FunctionComponent = () => {
  const { isSuperadmin } = useContext(AppContext);
  const clientId = useClientId();
  const { mplaceId } = useParams<RouteParams>();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [invoicePage, setInvoicePage] = useState<number>(0);
  const [invoiceRowsPerPage, setInvoiceRowsPerPage] = useState<number>(10);
  const [uploadedInvoicesPage, setUploadedInvoicesPage] = useState<number>(0);
  const [uploadedInvoicesRowsPerPage, setUploadedInvoicesRowsPerPage] = useState<number>(10);
  const [invoiceTypeFilters, setInvoiceTypeFilters] = useState<number[]>([]);

  const [sortOrderInvoices, setSortOrderInvoices] = useState<'asc' | 'desc'>('asc');
  const [sortByInvoices, setSortByInvoices] = useState<string | number>('customer_name');

  const [sortOrderUploads, setSortOrderUploads] = useState<'asc' | 'desc'>('asc');
  const [sortByUploads, setSortByUploads] = useState<string | number>('mplace_name');

  const now = moment();
  const [uploadedStartDate, setUploadedSartDate] = useState<string | undefined>(
    now.startOf('year').format('YYYY-MM-DD')
  );
  const [uploadedEndDate, setUploadedEndDate] = useState<string | undefined>(now.endOf('year').format('YYYY-MM-DD'));

  const { isLoading, isError, error, data, remove, refetch } = useQuery<MeasurementPlaceSingle, ApiError>(
    'mplace',
    ApiService.get(`/client/${clientId}/mplace/${mplaceId}`, {})
  );

  const {
    isError: isTypeError,
    isLoading: isTypeLoading,
    data: typeData,
    refetch: typeRefetch,
    error: typeError,
  } = useQuery<InvoiceTypesResponse, ApiError>('types', ApiService.get(`/client/${clientId}/invoice-types`));

  const {
    isLoading: isInvoiceLoading,
    isError: isInvoiceError,
    data: invoiceData,
    error: invoiceError,
    refetch: invoiceRefetch,
  } = usePaginatedQuery<InvoiceResponse, ApiError>(
    ['invoices', invoicePage, invoiceRowsPerPage, invoiceTypeFilters, sortByInvoices, sortOrderInvoices],
    ApiService.get(`/client/${clientId}/mplace/${mplaceId}/invoices`, {
      page: invoicePage,
      rowsPerPage: invoiceRowsPerPage,
      typeId: invoiceTypeFilters,
      sortBy: sortByInvoices,
      order: sortOrderInvoices,
    })
  );

  const {
    isLoading: uploadedInvoicesIsLoading,
    isError: uploadedInvoicesIsError,
    data: uploadedInvoicesData,
    error: uploadedInvoicesError,
    refetch: uploadedInvoicesRefetch,
  } = usePaginatedQuery<OriginalInvoicesResponse, ApiError>(
    [
      'uploaded-invoices-mplace',
      invoicePage,
      invoiceRowsPerPage,
      uploadedStartDate,
      uploadedEndDate,
      sortByUploads,
      sortOrderUploads,
    ],
    ApiService.get(`/client/${clientId}/original-invoices`, {
      startDate: uploadedStartDate,
      endDate: uploadedEndDate,
      page: uploadedInvoicesPage,
      rowsPerPage: uploadedInvoicesRowsPerPage,
      mplaceIds: [mplaceId],
      sortBy: sortByUploads,
      order: sortOrderUploads,
    })
  );

  useEffect(() => {
    return () => remove();
  }, [remove]);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setInvoicePage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setInvoiceRowsPerPage(parseInt(e.target.value, 10));

  const handleUploadedChangePage: TablePaginationProps['onChangePage'] = (_, newPage) =>
    setUploadedInvoicesPage(newPage);

  const handleUploadedChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setUploadedInvoicesRowsPerPage(parseInt(e.target.value, 10));

  const handleAddInvoice = () => {
    history.push(`/client/${clientId}/upload-invoice`, {
      mplaceId,
    });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return (
      <ErrorWrapper>
        <Typography variant="body2">{error && ApiService.getErrorMessage(error.response.data.code)}</Typography>
        <Button color="primary" onClick={() => refetch()}>
          Pokušaj ponovno
        </Button>
      </ErrorWrapper>
    );
  }

  return (
    <ContentWrapper>
      <StyledPageHeader>
        <Typography variant="h6">{data.mplace.name}</Typography>
        {parseInt(data.clientRole, 10) >= 10 || parseInt(data.mplaceRole, 10) >= 10 || isSuperadmin ? (
          <PageHeaderButtons>
            <StyledButton color="primary" onClick={handleOpenEdit}>
              <Edit />
              <Typography variant="button">Uredi</Typography>
            </StyledButton>
            <StyledButton color="secondary" onClick={handleOpenDelete} isDelete>
              <DeleteForever />
              <Typography variant="button">Izbriši</Typography>
            </StyledButton>
          </PageHeaderButtons>
        ) : null}
      </StyledPageHeader>
      <StyledDivider />
      <MarginWrapper>
        <CardsContainer mplaceId={mplaceId} />
        <FlexWrapper>
          <FlexItemWrapper>
            <DashboardGraph mplaceId={mplaceId} />
          </FlexItemWrapper>
          <GoogleMap lat={data.mplace.lat || 0} lng={data.mplace.lng || 0} title={data.mplace.name || ''} />
        </FlexWrapper>
        {parseInt(data.clientRole, 10) >= 10 || parseInt(data.mplaceRole, 10) >= 10 || isSuperadmin ? (
          <Grid item xs={12}>
            <ButtonRowWrapper>
              <>
                <MplaceForm
                  name={data.mplace.name}
                  address={data.mplace.address}
                  notes={data.mplace.notes}
                  open={openEdit}
                  handleClose={handleCloseEdit}
                  clientId={clientId}
                  refetch={refetch}
                  currentMplaceId={parseInt(mplaceId, 10)}
                  isNew={false}
                />
                {parseInt(data.clientRole, 10) >= 10 || parseInt(data.mplaceRole, 10) >= 10 || isSuperadmin ? (
                  <DeleteMplace
                    open={openDelete}
                    handleClose={handleCloseDelete}
                    currentMplaceId={parseInt(mplaceId, 10)}
                    clientId={clientId}
                    refetch={refetch}
                  />
                ) : null}
              </>
            </ButtonRowWrapper>
          </Grid>
        ) : null}
      </MarginWrapper>
      <Paper>
        <StyledTableHeader>
          <Typography variant="h6">Računi</Typography>
          <FilterInput
            isLoading={isTypeLoading}
            isError={isTypeError}
            data={typeData?.invoiceTypes}
            error={typeError}
            refetch={typeRefetch}
            label="Filtrirajte račune po tipu"
            placeholder="Tipovi"
            stateSetter={setInvoiceTypeFilters}
          />
        </StyledTableHeader>
        <MplaceInvoicesTableContent
          isLoading={isInvoiceLoading}
          isError={isInvoiceError}
          error={invoiceError}
          data={invoiceData?.invoices}
          refetch={invoiceRefetch}
          routePath={`${url}/invoice`}
          head={[
            {
              id: 'order_number',
              label: 'Redni broj',
              sortable: false,
            },
            {
              id: 'customer_name',
              label: 'Platitelj',
              sortable: true,
            },
            {
              id: 'amount',
              label: 'Iznos',
              sortable: true,
            },
            {
              id: 'month',
              label: 'Period',
              sortable: true,
            },
            {
              id: 'details',
              label: 'Detalji',
              sortable: false,
            },
          ]}
          pagination={{
            rowsPerPage: invoiceRowsPerPage,
            page: invoicePage,
            count: invoiceData ? parseInt(invoiceData.count) : 0,
            rowsPerPageOptions: [10, 20, 50, 100],
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          orderBy={sortByInvoices}
          order={sortOrderInvoices}
          onRequestSort={(_, sortProp) => {
            setSortByInvoices(sortProp);
            setSortOrderInvoices((prev) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        />
      </Paper>
      {parseInt(data.clientRole, 10) >= 10 || parseInt(data.mplaceRole, 10) >= 10 || isSuperadmin ? (
        <StyledFloatingActionButton onClick={handleAddInvoice} color="primary">
          <Add />
        </StyledFloatingActionButton>
      ) : null}
      <StyledDivider />
      <Paper>
        <StyledTableHeader>
          <Typography variant="h6">Originalni računi</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DateInputsWrapper>
              <DatePickerWrapper>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  openTo="year"
                  views={['year', 'month']}
                  label="Od"
                  value={uploadedStartDate}
                  maxDate={moment().endOf('month').format('YYYY-MM-DD')}
                  onChange={(date) => setUploadedSartDate(date?.format('YYYY-MM-01'))}
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  openTo="year"
                  views={['year', 'month']}
                  label="Do"
                  value={uploadedEndDate}
                  minDate={uploadedStartDate}
                  maxDate={moment().endOf('year').format('YYYY-MM-DD')}
                  onChange={(date) => setUploadedEndDate(date?.format('YYYY-MM-DD'))}
                />
              </DatePickerWrapper>
            </DateInputsWrapper>
          </MuiPickersUtilsProvider>
        </StyledTableHeader>
        <MplaceInvoicesTableContent
          isLoading={uploadedInvoicesIsLoading}
          isError={uploadedInvoicesIsError}
          error={uploadedInvoicesError}
          data={uploadedInvoicesData?.originalInvoices}
          refetch={uploadedInvoicesRefetch}
          head={[
            {
              id: 'order_number',
              label: 'Redni broj',
              sortable: false,
            },
            {
              id: 'mplace_name',
              label: 'Mjerno mjesto',
              sortable: true,
            },
            {
              id: 'amount',
              label: 'Iznos',
              sortable: true,
            },
            {
              id: 'month',
              label: 'Period',
              sortable: true,
            },
            {
              id: 'download',
              label: 'Preuzimanje',
              sortable: false,
            },
          ]}
          pagination={{
            rowsPerPage: invoiceRowsPerPage,
            page: invoicePage,
            count: uploadedInvoicesData ? parseInt(uploadedInvoicesData.count) : 0,
            rowsPerPageOptions: [10, 20, 50, 100],
            onChangePage: handleUploadedChangePage,
            onChangeRowsPerPage: handleUploadedChangeRowsPerPage,
          }}
          orderBy={sortByUploads}
          order={sortOrderUploads}
          onRequestSort={(_, sortProp) => {
            setSortByUploads(sortProp);
            setSortOrderUploads((prev) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        />
      </Paper>
    </ContentWrapper>
  );
};
