import React, { useEffect, FunctionComponent, useContext } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { ApiService } from '../../services/api';
import { AuthResponse } from '../../types/auth';
import { ApiError } from '../../types/api_error';
import { AppContext } from '../../context/app';

const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type AuthPageBody = {
  path: string;
  data: {
    token: string | null;
  };
};

export const AuthPage: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const passwordLessToken = params.get('t');
  const { setIsSuperadmin, setUserId, setErrorMessage } = useContext(AppContext);

  const [postAuth] = useMutation<AuthResponse, ApiError, AuthPageBody, unknown>(ApiService.post(), {
    onError: (error) => {
      setErrorMessage(ApiService.getErrorMessage(error.response.data.code));
      history.push('/login');
    },
    onSuccess: (data) => {
      localStorage.setItem('token', data.token);
      ApiService.refreshAuthToken(data.token);
      setIsSuperadmin(data.isSuperadmin);
      setUserId(data.userId);
      history.push('/client');
    },
  });

  useEffect(() => {
    postAuth({ path: '/auth/token', data: { token: passwordLessToken } });
  }, [postAuth, passwordLessToken]);

  return (
    <PageWrapper>
      <CircularProgress />
    </PageWrapper>
  );
};
