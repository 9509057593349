import React, { FunctionComponent, useState, useEffect, useContext, ChangeEvent, ReactElement } from 'react';
import { usePaginatedQuery, useQuery, useMutation, MutationResult } from 'react-query';
import {
  Typography,
  TableRow,
  TableCell,
  TablePaginationProps,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Button,
  InputLabel,
  Divider,
  Switch,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Edit, Add } from '@material-ui/icons';
import {
  Table,
  ConfirmationButtons,
  StyledFloatingActionButton,
  StyledDivider,
  LoadingIndicator,
  ContentWrapper,
} from '../../components/index';
import { ApiService } from '../../services/api';
import styled from 'styled-components';
import { ApiError } from '../../types/api_error';
import { MeasurementPlace, UserMplaceResponse } from '../../types/mplace';
import { useParams, Redirect } from 'react-router-dom';
import joi from '@hapi/joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { CustomModal } from '../../components/CustomModal';
import { mapper, roleEnum } from '../../types/user';
import { User } from '../../types/users';
import { useClientId } from '../Routes/useClientId';
import { AppContext } from '../../context/app';
import { MutateFunction } from 'react-query';

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const StyledTextField = styled(TextField)`
  margin: 10px 0;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type SingleUserParams = {
  userId: string;
};

type MplaceRowProps = {
  refetch: () => void;
  name: string;
  userId: number;
  mplaceId: number;
  number: number;
  userRole: roleEnum;
  editMode: boolean;
  setEditMode: (id: number | null) => void;
  editUserRole: MutateFunction<{}, ApiError, { path: string; data: { role: number } }, unknown>;
  editUserRoleReqInfo: MutationResult<object, ApiError>;
};

type NewRoleProps = {
  open: boolean;
  handleClose: () => void;
  clientId?: number;
  refetch: () => void;
  mplacesNot: MeasurementPlace[];
  userId: number;
  editUserRole: MutateFunction<{}, ApiError, { path: string; data: { role: number } }, unknown>;
  editUserRoleReqInfo: MutationResult<object, ApiError>;
};

type NewRoleForm = {
  userRole: roleEnum;
  mplaceId: number;
};

const newRoleSchema = joi.object({
  mplaceId: joi.number().positive().required(),
  userRole: joi.number().default(0),
});

const NewRole: FunctionComponent<NewRoleProps> = ({
  open,
  handleClose,
  clientId,
  refetch,
  mplacesNot,
  userId,
  editUserRole,
  editUserRoleReqInfo,
}) => {
  const [mplaceAutoCompleteOpen, setMplaceAutoCompleteOpen] = useState(false);
  const [userRole, setUserRole] = useState(0);

  const { register, handleSubmit, errors, setValue, reset } = useForm<NewRoleForm>({
    resolver: joiResolver(newRoleSchema),
  });

  useEffect(() => {
    register('mplaceId');
  }, [register]);

  const onSubmit = async (data: NewRoleForm) => {
    await editUserRole({
      path: `/client/${clientId}/mplace/${data.mplaceId}/user/${userId}`,
      data: { role: data.userRole },
    });
  };

  const onSuccess = () => {
    refetch();
    reset();
    editUserRoleReqInfo.reset();
    handleClose();
  };

  const onError = (tryAgain: boolean) => {
    if (tryAgain) {
      editUserRoleReqInfo.reset();
      reset();
      return;
    }
    handleClose();
  };

  const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUserRole(event.target.value as number);
  };

  const getModalContent = (): ReactElement => {
    if (editUserRoleReqInfo.isLoading) {
      return <LoadingIndicator />;
    }
    if (editUserRoleReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">
            {ApiService.getErrorMessage(editUserRoleReqInfo.error.response.data.code)}
          </Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={() => onError(true)}>
              Pokušaj ponovno
            </Button>
            <Button onClick={() => onError(false)} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    if (editUserRoleReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">Korisnik uspješno dodan na mjerno mjesto</Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              U redu
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }
    return (
      <>
        <Typography variant="h6">Dodavanje novog mjernog mjesta korisniku</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <Autocomplete
            fullWidth
            open={mplaceAutoCompleteOpen}
            onOpen={() => setMplaceAutoCompleteOpen(true)}
            onClose={() => setMplaceAutoCompleteOpen(false)}
            onChange={(_, val) => {
              if (val) setValue('mplaceId', val.value);
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.displayValue}
            options={mplacesNot?.map((el) => ({ value: el.id, displayValue: el.name }))}
            noOptionsText="Nema rezultata"
            renderInput={(params) => {
              return (
                <StyledTextField
                  {...params}
                  label="Mjerno mjesto"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    error: !!errors.mplaceId,
                    name: 'mplaceId',
                  }}
                  helperText={errors.mplaceId ? 'Mjerno mjesto je obavezno' : ''}
                />
              );
            }}
          />
          <FormControl fullWidth variant="standard">
            <InputLabel>Uloga</InputLabel>
            <Select value={userRole} onChange={handleRoleChange} inputProps={{ name: 'role' }}>
              <MenuItem value={0}>Čitanje</MenuItem>
              <MenuItem value={10}>Pisanje</MenuItem>
            </Select>
          </FormControl>
          <Divider />
          <ButtonsWrapper>
            <Button type="submit" color="primary">
              Spremi
            </Button>
            <Button onClick={handleClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </form>
      </>
    );
  };

  return (
    <CustomModal open={open} onClose={handleClose}>
      {getModalContent()}
    </CustomModal>
  );
};

const UserMplaceRow: FunctionComponent<MplaceRowProps> = ({
  refetch,
  name,
  userId,
  mplaceId,
  number,
  userRole,
  editMode,
  setEditMode,
  editUserRole,
  editUserRoleReqInfo,
}) => {
  const clientId = useClientId();
  const [userRoleValue, setUserRoleValue] = useState<roleEnum>(userRole);

  const editUserRoleHandler = async () => {
    await editUserRole({
      path: `/client/${clientId}/mplace/${mplaceId}/user/${userId}`,
      data: { role: userRoleValue },
    });
    refetch();
    editUserRoleReqInfo.reset();
    setEditMode(null);
  };

  const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUserRoleValue(event.target.value as number);
  };

  return (
    <TableRow>
      <TableCell>{number}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {editMode ? (
          <FormControl variant="outlined">
            <Select defaultValue={userRole} value={userRoleValue} onChange={handleRoleChange}>
              <MenuItem value={-1}>NemaPristup</MenuItem>
              <MenuItem value={0}>Čitanje</MenuItem>
              <MenuItem value={10}>Pisanje</MenuItem>
            </Select>
          </FormControl>
        ) : (
          mapper[userRole]
        )}
      </TableCell>
      <TableCell>
        {!editMode ? (
          <IconButton onClick={() => setEditMode(mplaceId)}>
            <Edit color={'primary'} />
          </IconButton>
        ) : (
          <ConfirmationButtons
            confirmHandler={editUserRoleHandler}
            cancelHandler={() => setEditMode(null)}
            disabled={userRoleValue === userRole}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export const SingleUser: FunctionComponent = () => {
  const { setErrorMessage } = useContext(AppContext);
  const { userId } = useParams<SingleUserParams>();
  const clientId = useClientId();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentEditingMplace, setCurrentEditingMplace] = useState<number | null>(null);
  const [editUserRole, editUserRoleReqInfo] = useMutation<
    {},
    ApiError,
    { path: string; data: { role: number } },
    unknown
  >(ApiService.patch());
  const [openNewRole, setOpenNewRole] = useState(false);
  const {
    isLoading: isUserLoading,
    isError: isUserError,
    data: userData,
    error: userError,
    refetch: userRefetch,
  } = useQuery<User, ApiError>(['user'], ApiService.get(`/client/${clientId}/user/${userId}`));
  const { isLoading, isError, data, error, refetch } = usePaginatedQuery<UserMplaceResponse, ApiError>(
    ['mplaces', page, rowsPerPage, clientId],
    ApiService.get(`/client/${clientId}/mplace`, {
      page: page,
      rowsPerPage: rowsPerPage,
      userId: parseInt(userId, 10),
      isForMplacesPage: false,
    }),
    {
      cacheTime: 1,
    }
  );

  const [deactivateUser] = useMutation<{}, ApiError, { path: string }, unknown>(ApiService.delete(), {
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
    onSuccess: userRefetch,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    deactivateUser({
      path: `/client/${clientId}/user/${userId}?deactivate=${event.target.checked}`,
    });

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  const handleOpenNewRole = () => {
    setOpenNewRole(true);
  };

  const handleCloseNewRole = () => {
    setOpenNewRole(false);
  };

  if (isUserLoading || isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || isUserError || !data || !userData) {
    return <Redirect to={{ pathname: '/error', state: { error: error || userError } }} />;
  }

  const mappedData = data.mplaces.map(({ id, name, userRole }, i) => (
    <UserMplaceRow
      key={id}
      refetch={refetch}
      name={name}
      userId={parseInt(userId, 10)}
      number={i + 1}
      mplaceId={id}
      userRole={userRole}
      setEditMode={setCurrentEditingMplace}
      editMode={currentEditingMplace === id}
      editUserRole={editUserRole}
      editUserRoleReqInfo={editUserRoleReqInfo}
    />
  ));

  return (
    <ContentWrapper>
      <Typography variant="h6">Mjerna mjesta korisnika {userData.email}</Typography>
      <FlexRow>
        <Typography variant="subtitle1">
          Pravo pristupa korisnika na klijentu: {mapper[(userData.userRole as unknown) as roleEnum] || 'Nema pristup'}.
        </Typography>
        <FlexElement>
          <Typography variant="body1">{userData.deactivated ? 'Korisnik deaktiviran' : 'Korisnik aktivan'}</Typography>
          <Switch size="medium" color="primary" checked={userData.deactivated} onChange={handleChange} />
        </FlexElement>
      </FlexRow>
      <StyledDivider />
      <Table
        head={['Redni broj', 'Ime', 'Pravo pristupa', 'Uredi']}
        pagination={{
          page,
          rowsPerPage,
          count: parseInt(data.count, 10),
          onChangePage: handleChangePage,
          onChangeRowsPerPage: handleChangeRowsPerPage,
        }}
      >
        {mappedData}
      </Table>
      <StyledFloatingActionButton color="primary" onClick={handleOpenNewRole}>
        <Add />
      </StyledFloatingActionButton>
      <NewRole
        open={openNewRole}
        handleClose={handleCloseNewRole}
        clientId={clientId}
        refetch={refetch}
        mplacesNot={data.mplacesNot}
        editUserRole={editUserRole}
        editUserRoleReqInfo={editUserRoleReqInfo}
        userId={parseInt(userId, 10)}
      />
    </ContentWrapper>
  );
};
