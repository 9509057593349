import React, { FunctionComponent, useState } from 'react';
import { useQuery, usePaginatedQuery } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import { Typography, Paper, TablePaginationProps } from '@material-ui/core';
import styled from 'styled-components';
import { StyledDivider, LoadingIndicator, ContentWrapper, SortableTable } from '../../components/index';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { Supplier, SupplierInvoicesResponse } from '../../types/supplier';
import { useClientId } from '../Routes/useClientId';
import { InvoicesTableRow } from '../shared/InvoicesTableRow';
import { Invoice, InvoiceOriginal } from '../../types/invoice';

type InvoiceDetailsLocationMatch = {
  supplierId?: string;
};

const PageWrapper = styled(ContentWrapper)`
  align-items: stretch;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
  }
  margin-bottom: 25px;
`;

const DataWrapper = styled.div`
  width: 49%;
  @media screen and (max-width: 1025px) {
    width: 100%;
  }
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const StyledInfoHeader = styled(Typography)`
  opacity: 0.6;
  font-weight: 500;
`;

const StyledInfo = styled(Typography)`
  font-weight: 400;
  margin-bottom: 10px;
`;

const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`;

const isOriginalInvoice = (invoice: Invoice | InvoiceOriginal): invoice is InvoiceOriginal => {
  return 'mplaceName' in invoice;
};

export const SupplierDetails: FunctionComponent = () => {
  const clientId = useClientId();
  const { supplierId } = useParams<InvoiceDetailsLocationMatch>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('supplier_name');

  const { data, isError, error, isLoading } = useQuery<Supplier, ApiError>(
    ['supplier-details', supplierId],
    ApiService.get(`/client/${clientId}/supplier/${supplierId}`)
  );

  const {
    data: invoicesData,
    isError: invoicesIsError,
    error: invoicesError,
    isLoading: invoicesIsLoading,
  } = usePaginatedQuery<SupplierInvoicesResponse, ApiError>(
    ['supplier-invoices', supplierId, page, rowsPerPage, sortBy, sortOrder],
    ApiService.get(`/client/${clientId}/supplier/${supplierId}/invoices`)
  );

  if (isLoading || invoicesIsLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data || invoicesIsError || !invoicesData) {
    return <Redirect to={{ pathname: '/error', state: { error: error || invoicesError } }} />;
  }

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  const mappedInvoices = invoicesData.invoices.map((invoice, i) => (
    <InvoicesTableRow
      key={invoice.id}
      orderNumber={i + 1}
      customer={isOriginalInvoice(invoice) ? invoice.mplaceName : invoice.customerName}
      amount={invoice.amount}
      period={`${invoice.month}/${invoice.year}`}
      id={invoice.id}
      routePath={`/client/${clientId}/mplace/${invoice.mplaceId}/invoice/${invoice.id}`}
    />
  ));

  return (
    <PageWrapper>
      <Typography variant="h6">{data.supplierName}</Typography>
      <StyledDivider />
      <StyledPaper>
        <DataWrapper>
          <StyledInfoHeader variant="caption">Dobavljač</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.supplierName}</StyledInfo>
          <StyledInfoHeader variant="caption">ID Dobavljača</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.supplierId}</StyledInfo>
          <StyledInfoHeader variant="caption">OIB Dobavljača</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.companyId}</StyledInfo>
          <StyledInfoHeader variant="caption">Ukupan broj dostavljenih računa</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{invoicesData.count}</StyledInfo>
          <StyledInfoHeader variant="caption">Ukupna potrošnja</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{invoicesData.amount} HRK</StyledInfo>
        </DataWrapper>
        <DataWrapper>
          <StyledInfoHeader variant="caption">Telefon</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.telephone || '-'}</StyledInfo>
          <StyledInfoHeader variant="caption">Telefax</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.telefax || '-'}</StyledInfo>
          <StyledInfoHeader variant="caption">Adresa</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.address || '-'}</StyledInfo>
          <StyledInfoHeader variant="caption">Email</StyledInfoHeader>
          <StyledInfo variant="subtitle1">
            <a href={'mailto:' + data.email}>{data.email || '-'}</a>
          </StyledInfo>
        </DataWrapper>
      </StyledPaper>
      <Paper>
        <StyledTableHeader>
          <Typography variant="h6">Računi</Typography>
        </StyledTableHeader>
        <SortableTable
          head={[
            {
              id: 'order_number',
              label: 'Redni broj',
              sortable: false,
            },
            {
              id: 'customer_name',
              label: 'Platitelj',
              sortable: true,
            },
            {
              id: 'amount',
              label: 'Iznos',
              sortable: true,
            },
            {
              id: 'month',
              label: 'Period',
              sortable: true,
            },
            {
              id: 'details',
              label: 'Detalji',
              sortable: false,
            },
          ]}
          pagination={{
            rowsPerPage,
            page,
            count: parseInt(invoicesData.count || '0'),
            rowsPerPageOptions: [10, 20, 50, 100],
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          orderBy={sortBy}
          order={sortOrder}
          onRequestSort={(_, sortProp) => {
            setSortBy(sortProp);
            setSortOrder((prev: string) => (prev === 'asc' ? 'desc' : 'asc'));
          }}
        >
          {mappedInvoices}
        </SortableTable>
      </Paper>
    </PageWrapper>
  );
};
