import React, { FunctionComponent } from 'react';
import { Doughnut } from '@reactchartjs/react-chart.js';
import { useQuery } from 'react-query';
import { Typography, Button } from '@material-ui/core';
import { ApiService } from '../../services/api';
import styled from 'styled-components';
import moment from 'moment';
import { ApiError } from '../../types/api_error';
import { GraphResponse } from '../../types/dashboard_data';
import { useClientId } from '../Routes/useClientId';
import { LoadingIndicator } from '../../components';
import { getGraphOptions, getPieContent } from './GraphUtils';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  flex: 1;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 50px;
`;

export const PieGraph: FunctionComponent<{ mplaceId?: string }> = ({ mplaceId }) => {
  const clientId = useClientId();
  const pieStartDate = moment().subtract(1, 'month').startOf('month');
  const pieEndDate = moment().startOf('month').format('YYYY-MM-DD');

  const { data: pieData, isLoading: pieIsLoading, isError: pieIsError, refetch: pieRefetch } = useQuery<
    GraphResponse,
    ApiError
  >(
    [mplaceId ? `pie-graph-data-${clientId}-${mplaceId}` : `pie-graph-data-${clientId}`, clientId],
    ApiService.get(`/client/${clientId}/invoices/consumption`, {
      startDate: pieStartDate.format('YYYY-MM-DD'),
      endDate: pieEndDate,
      mplaceId,
    })
  );

  if (pieIsLoading) {
    return <LoadingIndicator />;
  }

  if (pieIsError || !pieData) {
    return (
      <GraphWrapper>
        <Typography variant="body2">Nažalost nismo uspjeli dohvatiti podatke.</Typography>
        <Button color="primary" onClick={() => pieRefetch}>
          Pokušaj ponovno
        </Button>
      </GraphWrapper>
    );
  }

  const pieGraphContent = getPieContent(pieData, pieStartDate);
  const pieOpts = getGraphOptions({});

  return (
    <GraphWrapper>
      {pieGraphContent.labels?.length ? (
        <>
          <StyledTypography variant="h6">Prethodni mjesec</StyledTypography>
          <Doughnut type="doughnut" data={pieGraphContent} options={pieOpts} width={1} height={1} />
        </>
      ) : (
        <InfoWrapper>
          <Typography variant="h6">Prethodni mjesec</Typography>
          <Typography variant="body1">Nema podataka</Typography>
        </InfoWrapper>
      )}
    </GraphWrapper>
  );
};
