export enum roleEnum {
  NONE = -1,
  READ = 0,
  WRITE = 10,
  ADMIN = 20,
  OWNER = 30,
}

export const mapper = {
  [roleEnum.NONE]: 'Nema pravo',
  [roleEnum.READ]: 'Čitanje',
  [roleEnum.WRITE]: 'Pisanje',
  [roleEnum.ADMIN]: 'Admin',
  [roleEnum.OWNER]: 'Vlasnik',
};
