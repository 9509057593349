import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';
import { ContentWrapper, LoadingIndicator, StyledDivider } from '../../components';
import { ApiService } from '../../services/api';
import { useClientId } from '../Routes/useClientId';
import { Invoice, InvoiceComparisonResponse, InvoiceOriginal } from '../../types/invoice';
import { ApiError } from '../../types/api_error';
import { theme } from '../../theme';
import { ComparisonDetails } from './InvoiceComparisonDetails';
import { pick as _pick, get as _get, omit as _omit } from 'lodash';
import { commonInvoicesProperties, invoicePropsMapper } from './InvoiceUtils';

const RowContentWrapper = styled(ContentWrapper)`
  flex-direction: row;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const LegendRect = styled.div<{ bgColor: string }>`
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.bgColor};
  border-radius: 2px;
  margin: 0px 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
`;

const StyledColumnWrapper = styled(ContentWrapper)`
  padding: 0 20px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

type InvoiceDetailsLocationMatch = {
  invoiceId?: string;
  mplaceId?: string;
};

export const InvoiceComparison: FunctionComponent = () => {
  const clientId = useClientId();
  const { invoiceId, mplaceId } = useParams<InvoiceDetailsLocationMatch>();

  const { data, isError, error, isLoading } = useQuery<InvoiceComparisonResponse, ApiError>(
    ['invoice-detail-comparison', invoiceId],
    ApiService.get(`/client/${clientId}/mplace/${mplaceId}/invoice-comparison/${invoiceId}`)
  );

  if (isLoading) return <LoadingIndicator />;

  if (isError || !data) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  const invoice: Partial<Invoice> = _pick(data.invoiceData, commonInvoicesProperties);
  const originalInvoice: Partial<InvoiceOriginal> = _pick(data.invoiceOriginalData, commonInvoicesProperties);
  const invoiceAdditionals = data.invoiceData.additionalItems.sort((a, b) => a.id - b.id);
  const invoiceOriginalAdditionals = data.invoiceOriginalData.additionalItems.sort((a, b) => a.id - b.id);

  return (
    <ContentWrapper>
      <Typography variant="h6">Usporedba podataka u računima</Typography>
      <StyledDivider />
      <LegendContainer>
        <LegendItem>
          <LegendRect bgColor={theme.palette.success.main} />
          <Typography variant="caption">Nova vrijednost računa u ondnosu na prenesenu datoteku</Typography>
        </LegendItem>
        <LegendItem>
          <LegendRect bgColor={theme.palette.error.main} />
          <Typography variant="caption">Stvarna vrijednost računa u prenesenoj datoteci</Typography>
        </LegendItem>
        <LegendItem>
          <LegendRect bgColor={theme.palette.background.paper} />
          <Typography variant="caption">Nema promjene</Typography>
        </LegendItem>
      </LegendContainer>
      <RowContentWrapper>
        <StyledColumnWrapper>
          <Typography variant="h6">Originalni račun (xml)</Typography>
          <StyledDivider />
          <StyledPaper>
            {Object.keys(originalInvoice).map((key, i) => (
              <ComparisonDetails
                key={i}
                data={_get(originalInvoice, key, '-')}
                dataToCompareWith={_get(invoice, key)}
                title={invoicePropsMapper[key]}
                original
              />
            ))}
            {invoiceOriginalAdditionals.map((el, index) => (
              <React.Fragment key={'iad' + index}>
                <StyledDivider />
                <Typography>Dodatna stavka {index + 1}</Typography>
                {Object.keys(_omit(el, ['id', 'invoiceOriginalId'])).map((key, i) => (
                  <ComparisonDetails
                    key={'iaddit' + i}
                    data={_get(el, key, '-')}
                    dataToCompareWith={_get(_get(invoiceAdditionals, index), key)}
                    title={invoicePropsMapper[key]}
                    original
                  />
                ))}
              </React.Fragment>
            ))}
          </StyledPaper>
        </StyledColumnWrapper>
        <StyledColumnWrapper>
          <Typography variant="h6">Račun koji se koristi u obračunu potrošnje</Typography>
          <StyledDivider />
          <StyledPaper>
            {Object.keys(invoice).map((key, i) => (
              <ComparisonDetails
                key={i}
                data={_get(invoice, key, '-')}
                dataToCompareWith={_get(originalInvoice, key)}
                title={invoicePropsMapper[key]}
              />
            ))}
            {invoiceAdditionals.map((el, index) => (
              <React.Fragment key={'ioad' + index}>
                <StyledDivider />
                <Typography>Dodatna stavka {index + 1}</Typography>
                {Object.keys(_omit(el, ['id', 'invoiceId'])).map((key, i) => (
                  <ComparisonDetails
                    key={'iaddit' + i}
                    data={_get(el, key, '-')}
                    dataToCompareWith={_get(_get(invoiceOriginalAdditionals, index), key)}
                    title={invoicePropsMapper[key]}
                  />
                ))}
              </React.Fragment>
            ))}
          </StyledPaper>
        </StyledColumnWrapper>
      </RowContentWrapper>
    </ContentWrapper>
  );
};
