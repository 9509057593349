import React, { FunctionComponent, useContext } from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { ExitToApp, Menu } from '@material-ui/icons';
import { Dropdown } from './Dropdown';
import styled from 'styled-components';
import { AppContext } from '../context/app';
import { DropdownItem } from './Dropdown';
import { useHistory } from 'react-router-dom';
import { ApiService } from '../services/api';
import { StyledButton } from './index';

const Wrapper = styled.div`
  z-index: 1300;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 150px;
  * {
    color: white !important;
  }
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ElementsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenuButton = styled.div`
  display: none;
  @media screen and (max-width: 1025px) {
    display: block;
    margin-right: 15px;
  }
`;

type HeaderProps = {
  clients: DropdownItem[];
  setDrawerOpen: (isOpen: boolean) => void;
  drawerOpen: boolean;
  currentClientId: number;
};

export const Header: FunctionComponent<HeaderProps> = ({ clients, setDrawerOpen, drawerOpen, currentClientId }) => {
  const { clearContext } = useContext(AppContext);
  const history = useHistory();

  const handleChange = (value: number) => {
    localStorage.setItem('clientId', value.toString());
    history.push(`/client/${value}`);
  };

  const logout = () => {
    clearContext();
    ApiService.refreshAuthToken('');
    localStorage.clear();
    history.push('/login');
  };

  const closeDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Wrapper>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <HeaderContentWrapper>
            <ElementsWrapper>
              <StyledMenuButton onClick={closeDrawer}>
                <Menu />
              </StyledMenuButton>
              {clients.length > 1 && (
                <SelectWrapper data-testid="header-multiple-clients">
                  <Dropdown
                    items={clients}
                    changeHandler={handleChange}
                    labelText="Odaberi klijenta"
                    selectedValue={currentClientId}
                  />
                </SelectWrapper>
              )}
              {clients.length === 1 && (
                <Typography data-testid="header-one-client">{clients[0].displayValue}</Typography>
              )}
            </ElementsWrapper>
            <StyledButton onClick={logout}>
              <ExitToApp />
              <Typography variant="button">Odjava</Typography>
            </StyledButton>
          </HeaderContentWrapper>
        </Toolbar>
      </AppBar>
    </Wrapper>
  );
};
