import React, { FunctionComponent } from 'react';
import {
  TableProps as MUITableProps,
  TablePaginationProps as MUITablePaginationProps,
  TableCellProps,
  TableContainer,
  Table as MUITable,
  TablePagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
} from '@material-ui/core';

interface Head {
  id: string;
  label: string;
  sortable: boolean;
}

interface TableProps extends MUITableProps {
  alignHead?: TableCellProps['align'];
  head: Head[];
  pagination?: MUITablePaginationProps;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof { [key: string]: unknown }) => void;
  orderBy: string | number;
  order: 'asc' | 'desc';
}

export const SortableTable: FunctionComponent<TableProps> = ({
  head,
  children,
  alignHead = 'justify',
  pagination,
  onRequestSort,
  orderBy,
  order,
}) => {
  const createSortHandler = (property: keyof { [key: string]: unknown }) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer component={Paper}>
      <MUITable data-testid="Table_Id">
        <TableHead>
          <TableRow>
            {head.map((td) => {
              return td.sortable ? (
                <TableCell key={td.id}>
                  <TableSortLabel
                    active={orderBy === td.id}
                    direction={orderBy === td.id ? order : 'asc'}
                    onClick={createSortHandler(td.id)}
                  >
                    {td.label}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell align={alignHead} key={td.id}>
                  {td.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MUITable>
      {pagination && (
        <TablePagination
          component="div"
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          count={pagination.count}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          onChangePage={pagination.onChangePage}
          onChangeRowsPerPage={pagination.onChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};
