import React, { FunctionComponent, useState, useContext } from 'react';
import { usePaginatedQuery, useMutation, useQueryCache } from 'react-query';
import { Typography, TablePaginationProps, Divider } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  SortableTable,
  StyledFloatingActionButton,
  ContentWrapper,
  LoadingIndicator,
  StyledDivider,
} from '../../components/index';
import { AppContext } from '../../context/app';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { Redirect, useHistory } from 'react-router-dom';
import { NewClient } from './NewClient';
import { ClientRow } from './ClientRow';
import { useClientId } from '../Routes/useClientId';
import { ClientResponse } from '../../types/client';

export const AllClients: FunctionComponent = () => {
  const { setErrorMessage } = useContext(AppContext);
  const history = useHistory();
  const clientId = useClientId();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currEditingClient, setCurrEditingClient] = useState<number | null>(null);
  const [currDeletingClient, setCurrDeletingClient] = useState<number | null>(null);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const query = useQueryCache();
  const clientsQuery = query.getQuery('getting-clients');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('name');

  const { isLoading, isError, data, error, refetch } = usePaginatedQuery<ClientResponse, ApiError>(
    ['users', page, rowsPerPage, sortOrder, sortBy],
    ApiService.get(`/client`, {
      page,
      rowsPerPage,
      sortBy,
      order: sortOrder,
    })
  );

  const [editClient] = useMutation<{}, ApiError, { path: string; data: { name: string } }, unknown>(ApiService.put(), {
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
    onSuccess: (_) => {
      setCurrEditingClient(null);
      refetch();
      if (clientsQuery) clientsQuery.refetch();
    },
  });
  const [deleteClient] = useMutation<{}, ApiError, { path: string }, unknown>(ApiService.delete(), {
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
    onSuccess: (_) => {
      setCurrDeletingClient(null);
      refetch();
      if (clientsQuery) clientsQuery.refetch();
      if (currDeletingClient === clientId) {
        localStorage.removeItem('clientId');
        history.push('/client');
      }
    },
  });

  const deleteClientHandler = () => {
    deleteClient({ path: `/client/${currDeletingClient}` });
  };

  const editClientNameHandler = (name: string) => {
    editClient({ path: `/client/${currEditingClient}`, data: { name } });
  };

  const handleChangePage: TablePaginationProps['onChangePage'] = (e, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const head = [
    {
      id: 'order_number',
      label: 'Redni broj',
      sortable: false,
    },
    {
      id: 'name',
      label: 'Ime',
      sortable: true,
    },
    {
      id: 'edit',
      label: 'Uredi',
      sortable: false,
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const mappedData = data.clients.map(({ id, name }, i) => (
    <ClientRow
      clientId={id}
      clientName={name}
      number={i + 1}
      key={id}
      setEditMode={setCurrEditingClient}
      setDeleteMode={setCurrDeletingClient}
      editMode={currEditingClient === id}
      deleteMode={currDeletingClient === id}
      editClient={editClientNameHandler}
      deleteClient={deleteClientHandler}
    />
  ));

  return (
    <ContentWrapper>
      <Typography variant="h6">Klijenti</Typography>
      <StyledDivider />
      <SortableTable
        head={head}
        pagination={{
          page,
          rowsPerPage,
          count: parseInt(data.count || '0'),
          onChangePage: handleChangePage,
          onChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        orderBy={sortBy}
        order={sortOrder}
        onRequestSort={(_, sortProp) => {
          setSortBy(sortProp);
          setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        }}
      >
        {mappedData}
      </SortableTable>
      <Divider />
      <StyledFloatingActionButton onClick={handleOpenNew} color="primary">
        <Add />
      </StyledFloatingActionButton>
      <NewClient open={openNew} handleClose={handleCloseNew} refetch={refetch} />
    </ContentWrapper>
  );
};
