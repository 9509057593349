import React, { FunctionComponent, useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { isEmpty as _isEmpty } from 'lodash';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Button, Paper } from '@material-ui/core';
import { ExpandMoreOutlined, Edit, DeleteForever, CompareArrows } from '@material-ui/icons';
import styled from 'styled-components';
import { StyledButton, StyledDivider, LoadingIndicator, ContentWrapper } from '../../components/index';
import { AppContext } from '../../context/app';
import { ApiService } from '../../services/api';
import { ApiError } from '../../types/api_error';
import { Invoice } from '../../types/invoice';
import { MeasurementPlaceSingle } from '../../types/mplace';
import { CustomModal } from '../../components/CustomModal';
import { useClientId } from '../Routes/useClientId';

type InvoiceDetailsLocationMatch = {
  invoiceId?: string;
  mplaceId?: string;
};

const PageWrapper = styled(ContentWrapper)`
  align-items: stretch;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
  }
  margin-bottom: 25px;
`;

const DataWrapper = styled.div`
  width: 49%;
  @media screen and (max-width: 1025px) {
    width: 100%;
  }
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

const StyledInfoHeader = styled(Typography)`
  opacity: 0.6;
  font-weight: 500;
`;

const StyledInfo = styled(Typography)`
  font-weight: 400;
  margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  width: fit-content;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPageHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledAdditionalHeader = styled(Typography)`
  padding: 10px 15px;
`;

export const InvoiceDetails: FunctionComponent = () => {
  const { isSuperadmin } = useContext(AppContext);
  const history = useHistory();
  const clientId = useClientId();
  const { invoiceId, mplaceId } = useParams<InvoiceDetailsLocationMatch>();
  const { setErrorMessage } = useContext(AppContext);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);

  const { data, isError, error, isLoading } = useQuery<Invoice, ApiError>(
    ['invoice-detail', invoiceId],
    ApiService.get(`/client/${clientId}/mplace/${mplaceId}/invoice/${invoiceId}`)
  );
  const { data: mplace, isError: mplaceIsError, isLoading: mplaceIsLoading } = useQuery<
    MeasurementPlaceSingle,
    ApiError
  >('get-mplace', ApiService.get(`/client/${clientId}/mplace/${mplaceId}`));

  const [deleteInvoice] = useMutation<{}, ApiError, { path: string }, unknown>(ApiService.delete(), {
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
    onSuccess: () => history.goBack(),
  });

  const deleteInvoiceHandler = () => {
    deleteInvoice({ path: `/client/${clientId}/mplace/${mplaceId}/invoice/${invoiceId}` });
  };

  if (isLoading || mplaceIsLoading) {
    return <LoadingIndicator />;
  }

  if (isError || mplaceIsError || !mplace || !data) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  return (
    <PageWrapper>
      <StyledPageHeader>
        <Typography variant="h6">Detalji računa #{invoiceId}</Typography>
        {(parseInt(mplace.mplaceRole) >= 10 || parseInt(mplace.clientRole) >= 10 || isSuperadmin) && (
          <ButtonsContainer>
            {data.invoiceOriginalId && (
              <StyledButton
                onClick={() =>
                  history.push(`/client/${clientId}/measurement-places/${mplaceId}/invoice-comparison/${data.id}`, {
                    invoice: data,
                  })
                }
              >
                <CompareArrows />
                <Typography variant="button">Usporedi sa originalom</Typography>
              </StyledButton>
            )}
            <StyledButton onClick={() => history.push(`/client/${clientId}/create-invoice`, { invoice: data })}>
              <Edit />
              <Typography variant="button">Uredi</Typography>
            </StyledButton>
            <StyledButton onClick={() => setDeleteMode(true)} isDelete>
              <DeleteForever />
              <Typography variant="button">Izbriši</Typography>
            </StyledButton>
          </ButtonsContainer>
        )}
      </StyledPageHeader>
      <StyledDivider />
      <StyledPaper>
        <DataWrapper>
          <StyledInfoHeader variant="caption">Mjerno mjesto</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{mplace.mplace.name}</StyledInfo>
          <StyledInfoHeader variant="caption">Tip računa</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.types.join(', ')}</StyledInfo>
          <StyledInfoHeader variant="caption">Dobavljač</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.supplierName}</StyledInfo>
          <StyledInfoHeader variant="caption">ID korisnika</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.customerId}</StyledInfo>
          <StyledInfoHeader variant="caption">Ime korisnika</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.customerName}</StyledInfo>
          <StyledInfoHeader variant="caption">Iznos</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.amount + ' HRK'}</StyledInfo>
        </DataWrapper>
        <DataWrapper>
          <StyledInfoHeader variant="caption">Datum izdavanja</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.issuedAt}</StyledInfo>
          <StyledInfoHeader variant="caption">Datum početka</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.startDate}</StyledInfo>
          <StyledInfoHeader variant="caption">Datum završetka</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.endDate}</StyledInfo>
          <StyledInfoHeader variant="caption">Mjesec</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.month}</StyledInfo>
          <StyledInfoHeader variant="caption">Godina</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.year}</StyledInfo>
          <StyledInfoHeader variant="caption">Bilješke</StyledInfoHeader>
          <StyledInfo variant="subtitle1">{data.notes}</StyledInfo>
        </DataWrapper>
      </StyledPaper>
      <Paper>
        <StyledAdditionalHeader variant="h6">Dodatne stavke računa</StyledAdditionalHeader>
        {_isEmpty(data.additionalItems) ? (
          <StyledAdditionalHeader variant="body1">Ovaj račun nema dodatnih stavki</StyledAdditionalHeader>
        ) : (
          data.additionalItems.map((invoiceLine, i) => {
            return (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{invoiceLine.name}</Typography>
                </AccordionSummary>
                <StyledAccordionDetails>
                  <StyledInfoHeader variant="caption">Iznos u kunama</StyledInfoHeader>
                  <StyledInfo variant="subtitle1">{invoiceLine.priceAmount + ' HRK'}</StyledInfo>
                  <StyledInfoHeader variant="caption">Iznos u mjernoj jedinici</StyledInfoHeader>
                  <StyledInfo variant="subtitle1">{invoiceLine.unitAmount + ' ' + invoiceLine.unitCode}</StyledInfo>
                  <StyledInfoHeader variant="caption">Opis</StyledInfoHeader>
                  <StyledInfo variant="subtitle1">{invoiceLine.description}</StyledInfo>
                  <StyledInfoHeader variant="caption">Klasifikacijski kod</StyledInfoHeader>
                  <StyledInfo variant="subtitle1">{invoiceLine.classificationCode || '-'}</StyledInfo>
                </StyledAccordionDetails>
              </Accordion>
            );
          })
        )}
      </Paper>
      {deleteMode && (
        <CustomModal open={deleteMode} onClose={() => setDeleteMode(false)}>
          <ModalContent>
            <Typography variant="body2">Jeste li sigurni da želite izbirsati ovaj račun?</Typography>
            <ButtonsContainer>
              <Button color="default" onClick={() => deleteInvoiceHandler()}>
                Izbriši
              </Button>
              <Button color="secondary" onClick={() => setDeleteMode(false)}>
                Odustani
              </Button>
            </ButtonsContainer>
          </ModalContent>
        </CustomModal>
      )}
    </PageWrapper>
  );
};
