import { ChartOptions, ChartData } from 'chart.js';
import { GraphResponse } from '../../types/dashboard_data';
import { GraphLabels } from '../../types/graph';
import { orderBy, omit } from 'lodash';
import moment, { Moment } from 'moment';

export const graphColors = [
  '#1465C0',
  '#ff9800',
  '#43A047',
  '#2196f3',
  '#fbc02c',
  '#f44336',
  '#115293',
  '#7821ae',
  '#a1a1a1',
  '#b50833',
  '#c076dc',
  '#316e66',
  '#70d5cf',
  '#1336f6',
  '#ea4d2b',
  '#a787a2',
  '#86a7b0',
  '#c9d79d',
  '#8da023',
  '#f48e88',
];

export const getGraphContent = (graphData: GraphResponse, types: GraphLabels, isBarGraph: boolean): ChartData => {
  const { labels: invoiceTypes, calculatedData } = graphData;

  if (!invoiceTypes.includes('total')) invoiceTypes.unshift('total');
  const colorKey = isBarGraph ? 'backgroundColor' : 'borderColor';
  const dataKeys = orderBy(
    Object.keys(calculatedData),
    [(value) => moment(`01/${value}`).format('YYYY-MM-DD')],
    ['asc']
  );

  const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )})`;
  return {
    labels: dataKeys,
    datasets: invoiceTypes.map((type, i) => ({
      label: type.toUpperCase(),
      data: dataKeys.map((k) => (calculatedData[k][type] ? parseFloat(calculatedData[k][type].toFixed(2)) : null)),
      [colorKey]: graphColors[i] ? graphColors[i] : randomColor,
      hidden: types[type.toUpperCase()],
      fill: isBarGraph,
    })),
  };
};

export const getPieContent = ({ calculatedData }: GraphResponse, pieStartDate: Moment): ChartData => {
  const pieDataObject = omit(calculatedData[`${pieStartDate.format('MM/YYYY')}`], 'total') || {};
  const dataKeys = Object.keys(pieDataObject);
  return {
    labels: dataKeys,
    datasets: [
      {
        data: dataKeys.map((k) => parseFloat(pieDataObject[k].toFixed(2))),
        // omit first color so type colors will match on pie and bar/line chart
        backgroundColor: dataKeys.map((_, i) => graphColors.slice(1)[i]),
      },
    ],
  };
};

export const getGraphOptions = (
  scales: object,
  stateSetter?: React.Dispatch<React.SetStateAction<GraphLabels>>
): ChartOptions => {
  const options: ChartOptions = {
    legend: {
      onHover: function ({ target }: MouseEvent) {
        if (target) {
          (target as HTMLElement).style.cursor = 'pointer';
        }
      },
      labels: {
        usePointStyle: true,
        boxWidth: 9,
        fontSize: 12,
        padding: 5,
      },
    },
    hover: {
      onHover: function (e: MouseEvent) {
        if (e.target) {
          const point = this.getElementAtEvent(e);
          if (point.length) {
            (e.target as HTMLElement).style.cursor = 'pointer';
          } else {
            (e.target as HTMLElement).style.cursor = 'default';
          }
        }
      },
    },
    scales,
    maintainAspectRatio: true,
    responsive: true,
    cutoutPercentage: 80,
  };
  if (stateSetter && options.legend) {
    options.legend.onClick = function (_, element) {
      const additionalItem = element.text;
      if (additionalItem) {
        stateSetter((prev: GraphLabels) => ({ ...prev, [additionalItem]: !prev[additionalItem] }));
      }
    };
  }
  return options;
};
