import React, { FunctionComponent, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TextField } from '@material-ui/core';

interface GoogleAutocompleteProps {
  fieldRef: UseFormMethods['register'];
  name: string;
  id: string;
  placeholder: string;
  label: string;
  defaultValue: string | null;
  error: boolean;
  helperText?: string | null;
  onFocus: () => void;
}

export const GoogleAutocomplete: FunctionComponent<GoogleAutocompleteProps> = ({
  fieldRef,
  name,
  id,
  placeholder,
  label,
  defaultValue,
  error,
  helperText,
  onFocus,
}) => {
  useEffect(() => {
    // eslint-disable-next-line
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('address') as HTMLInputElement,
      {}
    );

    autocomplete.setComponentRestrictions({
      country: ['hr'],
    });
  }, []);

  return (
    <TextField
      fullWidth
      inputProps={{
        onFocus: onFocus,
        ref: fieldRef,
        name: name,
        id: id,
        placeholder: placeholder,
      }}
      label={label}
      variant="outlined"
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
    />
  );
};
