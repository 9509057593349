import React, { FunctionComponent, useState, ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { usePaginatedQuery } from 'react-query';
import { ApiService } from '../../services/api';
import { LoadingIndicator, StyledDivider, SortableTable, ContentWrapper } from '../../components/index';
import { StyledFloatingActionButton } from '../../components/index';
import { MplaceForm } from './MplaceForm';
import { Link as MUILink, Typography, TableRow, TableCell, Button, TablePaginationProps } from '@material-ui/core';
import { MeasurementPlaces } from '../../types/mplace';
import { Add, DeleteForever } from '@material-ui/icons';
import styled from 'styled-components';
import { ApiError } from '../../types/api_error';
import { DeleteMplace } from './DeleteMplace';
import { useClientId } from '../Routes/useClientId';
import { AppContext } from '../../context/app';

const ErrorWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
`;

const StyledDeleteForever = styled(({ ...props }) => <DeleteForever {...props} />)`
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  padding: 10px 15px;
  margin: 10px 0;
`;

export const AllMplaces: FunctionComponent<{ isDashboardPage?: boolean }> = ({ isDashboardPage }) => {
  const clientId = useClientId();
  const { isSuperadmin } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openNew, setOpenNew] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | number>('name');

  const { isLoading, data, error, refetch } = usePaginatedQuery<MeasurementPlaces, ApiError>(
    ['mplaces', page, rowsPerPage, clientId, sortOrder, sortBy],
    ApiService.get(`/client/${clientId}/mplace`, {
      page: page,
      rowsPerPage: rowsPerPage,
      sortBy,
      order: sortOrder,
    })
  );

  const handleRefetch = () => refetch();

  const handleChangePage: TablePaginationProps['onChangePage'] = (e, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!!error || !data) {
    return (
      <ErrorWrapper>
        <Typography variant="body2">{error && ApiService.getErrorMessage(error.response.data.code)}</Typography>
        <Button color="primary" onClick={handleRefetch}>
          Pokušaj ponovno
        </Button>
      </ErrorWrapper>
    );
  }
  const head = [
    {
      id: 'order_number',
      label: 'Redni broj',
      sortable: false,
    },
    {
      id: 'name',
      label: 'Ime',
      sortable: true,
    },
    {
      id: 'address',
      label: 'Adresa',
      sortable: true,
    },
    {
      id: 'notes',
      label: 'Dodatne informacije',
      sortable: false,
    },
    {
      id: 'empty1',
      label: '',
      sortable: false,
    },
  ];

  if (parseInt(data.clientRole, 10) >= 20 || isSuperadmin)
    head.push({
      id: 'empty2',
      label: '',
      sortable: false,
    });
  const mplaces = data.mplaces.map(({ id, name, address, notes }, i) => {
    const mplace: ReactNode[] = [i + 1, name, address, notes];
    if (parseInt(data.clientRole, 10) >= 20 || isSuperadmin) {
      mplace.push(
        <StyledDeleteForever
          color="error"
          onClick={() => {
            setDeletedId(id);
          }}
        />
      );
    }
    mplace.push(
      <MUILink component={Link} to={`/client/${clientId}/measurement-places/${id}`} key={id}>
        <Typography variant="button">Detalji</Typography>
      </MUILink>
    );
    return mplace;
  });
  const paginationProps = {
    rowsPerPage: rowsPerPage,
    page: page,
    count: parseInt(data.count || '0'),
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleCloseDelete = () => {
    setDeletedId(0);
  };

  return (
    <ContentWrapper>
      {isDashboardPage ? (
        <StyledTypography variant="h6">Mjerna mjesta</StyledTypography>
      ) : (
        <>
          <Typography variant="h6">Mjerna mjesta</Typography>
          <StyledDivider />
        </>
      )}
      <SortableTable
        alignHead="left"
        head={head}
        pagination={paginationProps}
        orderBy={sortBy}
        order={sortOrder}
        onRequestSort={(_, sortProp) => {
          setSortBy(sortProp);
          setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        }}
      >
        {mplaces.map((mplace, i) => (
          <TableRow key={i}>
            {mplace.map((cell, i) => (
              <TableCell key={i}>{cell}</TableCell>
            ))}
          </TableRow>
        ))}
      </SortableTable>
      <DeleteMplace
        open={!!deletedId}
        handleClose={handleCloseDelete}
        refetch={refetch}
        currentMplaceId={deletedId}
        clientId={clientId}
      />
      {!isDashboardPage &&
        (parseInt(data.clientRole, 10) >= 20 || isSuperadmin ? (
          <>
            <StyledFloatingActionButton onClick={handleOpenNew} color="primary">
              <Add />
            </StyledFloatingActionButton>
            <MplaceForm open={openNew} handleClose={handleCloseNew} clientId={clientId} refetch={refetch} />
          </>
        ) : null)}
    </ContentWrapper>
  );
};
