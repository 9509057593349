import React, { FunctionComponent } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ApiError } from '../../types/api_error';
import { ApiService } from '../../services/api';
import { get as _get } from 'lodash';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const ErrorPage: FunctionComponent = () => {
  const {
    state: { error },
  } = useLocation<{ error: ApiError }>();
  const history = useHistory();

  return (
    <ErrorWrapper>
      <Typography variant="body1">{ApiService.getErrorMessage(_get(error, 'response.data.code', 0))}</Typography>
      <Button title="Try again" color="primary" onClick={() => history.goBack()}>
        Pokušajte ponovo
      </Button>
    </ErrorWrapper>
  );
};
