import React, { FunctionComponent, useState, useContext, ChangeEvent } from 'react';
import { usePaginatedQuery, useMutation, MutateFunction } from 'react-query';
import {
  Typography,
  Switch,
  TableCell,
  TablePaginationProps,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Link as MUILink,
  TableRow,
} from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import {
  ConfirmationButtons,
  StyledFloatingActionButton,
  StyledDivider,
  LoadingIndicator,
  ContentWrapper,
  SortableTable,
} from '../../components/index';
import { UserResponse } from '../../types/users';
import { ApiService } from '../../services/api';
import styled from 'styled-components';
import { ApiError } from '../../types/api_error';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import { NewUser } from './NewUser';
import { useClientId } from '../Routes/useClientId';
import { AppContext } from '../../context/app';

const StyledTableRow = styled(({ deactivated, ...rest }) => <TableRow {...rest} />)`
  background-color: ${(props) => (props.deactivated ? '#E6E6E6' : '#FFFFFF')};
`;

const StyledTypography = styled(Typography)`
  color: #bdbdbd;
`;

export enum roleEnum {
  NONE = -1,
  READ = 0,
  WRITE = 10,
  ADMIN = 20,
  OWNER = 30,
}

export const mapper = {
  [roleEnum.NONE]: 'Nema pravo',
  [roleEnum.READ]: 'Čitanje',
  [roleEnum.WRITE]: 'Pisanje',
  [roleEnum.ADMIN]: 'Admin',
  [roleEnum.OWNER]: 'Vlasnik',
};

type UserRowProps = {
  email: string;
  userId: number;
  number: number;
  userRole: roleEnum;
  editMode: boolean;
  deactivated: boolean;
  setEditMode: (id: number | null) => void;
  editUserRole: MutateFunction<{}, ApiError, { path: string; data: { role: number } }, unknown>;
  deleteUser: MutateFunction<{}, ApiError, { path: string }, unknown>;
};

const UserRow: FunctionComponent<UserRowProps> = ({
  email,
  userId,
  number,
  userRole,
  deactivated,
  setEditMode,
  editMode,
  editUserRole,
  deleteUser,
}) => {
  const { userId: currentUserId } = useContext(AppContext);
  const clientId = useClientId();
  const [userRoleValue, setUserRoleValue] = useState<number>(userRole);
  const { url } = useRouteMatch();

  const editUserRoleHandler = () => {
    editUserRole({ path: `/client/${clientId}/user/${userId}`, data: { role: userRoleValue } });
    setEditMode(null);
  };

  const deleteUserHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    deleteUser({
      path: `/client/${clientId}/user/${userId}?deactivate=${event.target.checked}`,
    });
  };

  const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUserRoleValue(event.target.value as number);
  };

  const hasPermission: boolean = userRole === 30 || currentUserId === userId;

  return (
    <StyledTableRow deactivated={deactivated}>
      <TableCell>{number}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {editMode ? (
          <FormControl variant="outlined">
            <Select value={userRoleValue} onChange={handleRoleChange}>
              <MenuItem value={-1}>NemaPristup</MenuItem>
              <MenuItem value={0}>Čitanje</MenuItem>
              <MenuItem value={10}>Pisanje</MenuItem>
              <MenuItem value={20}>Admin</MenuItem>
            </Select>
          </FormControl>
        ) : (
          mapper[userRole]
        )}
      </TableCell>
      <TableCell>
        {!editMode ? (
          <IconButton disabled={hasPermission} onClick={() => setEditMode(userId)}>
            <Edit color={hasPermission ? 'disabled' : 'primary'} />
          </IconButton>
        ) : (
          <ConfirmationButtons
            confirmHandler={editUserRoleHandler}
            cancelHandler={() => setEditMode(null)}
            disabled={userRoleValue === userRole}
          />
        )}
      </TableCell>
      <TableCell>
        <Switch
          disabled={hasPermission}
          size="medium"
          color="primary"
          checked={deactivated}
          onChange={deleteUserHandler}
        />
      </TableCell>
      <TableCell>
        {userRole >= 10 ? (
          <StyledTypography variant="button">Detalji</StyledTypography>
        ) : (
          <MUILink component={Link} to={`${url}/${userId}`} key={userId}>
            <Typography variant="button">Detalji</Typography>
          </MUILink>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export const AllUsers: FunctionComponent = () => {
  const { setErrorMessage } = useContext(AppContext);
  const clientId = useClientId();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentEditingUser, setCurrentEditingUser] = useState<number | null>(null);
  const [openNew, setOpenNew] = useState<boolean>(false);

  const { isLoading, isError, data, error, refetch } = usePaginatedQuery<UserResponse, ApiError>(
    ['users', page, rowsPerPage, clientId],
    ApiService.get(`/user/client/${clientId}/users`, {
      page: page,
      rowsPerPage: rowsPerPage,
    }),
    {
      cacheTime: 1,
    }
  );

  const [editUserRole] = useMutation<{}, ApiError, { path: string; data: { role: number } }, unknown>(
    ApiService.patch(),
    {
      onSuccess: refetch,
      onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
    }
  );

  const [deleteUser] = useMutation<{}, ApiError, { path: string }, unknown>(ApiService.delete(), {
    onSuccess: refetch,
    onError: (error) => setErrorMessage(ApiService.getErrorMessage(error.response.data.code)),
  });

  const handleChangePage: TablePaginationProps['onChangePage'] = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = (e) =>
    setRowsPerPage(parseInt(e.target.value, 10));

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const head = [
    {
      id: 'order_number',
      label: 'Redni broj',
      sortable: false,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: false,
    },
    {
      id: 'access_right',
      label: 'Pravo pristupa',
      sortable: false,
    },
    {
      id: 'edit',
      label: 'Uredi',
      sortable: false,
    },
    {
      id: 'deactivate',
      label: 'Deaktiviraj',
      sortable: false,
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const mappedData = data.users.map(({ id, email, userRole, deactivated }, i) => (
    <UserRow
      key={id}
      email={email}
      userId={id}
      number={i + 1}
      userRole={userRole}
      deactivated={deactivated}
      setEditMode={setCurrentEditingUser}
      editMode={currentEditingUser === id}
      editUserRole={editUserRole}
      deleteUser={deleteUser}
    />
  ));

  return (
    <ContentWrapper>
      <Typography variant="h6">Korisnici</Typography>
      <Typography variant="subtitle1">Deaktivirani korisnici su zatamnjeni u tablici.</Typography>
      <StyledDivider />
      <SortableTable
        head={head}
        pagination={{
          page,
          rowsPerPage,
          count: parseInt(data.count || '0'),
          onChangePage: handleChangePage,
          onChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        orderBy={''}
        order={'asc'}
        onRequestSort={() => {
          return;
        }}
      >
        {mappedData}
      </SortableTable>
      <StyledFloatingActionButton onClick={handleOpenNew} color="primary">
        <Add />
      </StyledFloatingActionButton>
      <NewUser
        open={openNew}
        handleClose={handleCloseNew}
        clientId={clientId}
        refetch={refetch}
        currentMplaceId={null}
      />
    </ContentWrapper>
  );
};
