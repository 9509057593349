import styled from 'styled-components';
import { theme } from '../theme';

export const StyledButton = styled.div<{ isDelete?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ isDelete }) => (!isDelete ? theme.palette.primary.main : theme.palette.error.main)};
  color: white;
  margin: 0 7.5px;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    background-color: ${({ isDelete }) =>
      !isDelete ? theme.palette.primary.main + 'CC' : theme.palette.error.main + 'CC'};
  }

  & svg {
    margin-right: 5px;
    fill: white;
  }
`;
