import React, { FunctionComponent, ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useForm, UseFormMethods } from 'react-hook-form';
import joi from '@hapi/joi';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import styled from 'styled-components';
import { ApiService } from '../../services/api';
import { CustomModal, LoadingIndicator } from '../../components/index';
import { ApiError } from '../../types/api_error';
import { MeasurementPlacesResponse } from '../../types/mplace';

const Divider = styled.div`
  height: 1px;
  margin: 9.5px 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  margin: 10px 0;
`;

type MplaceAutocompleteProps = {
  clientId?: number;
  setValue: UseFormMethods['setValue'];
  register: UseFormMethods['register'];
  isError: boolean;
  errorText: string;
};

type NewUserProps = {
  open: boolean;
  handleClose: () => void;
  clientId?: number;
  refetch: () => void;
  currentMplaceId: number | null;
};

type FormInput = {
  email: string;
  role: number;
  mplacesId: number[];
};

const userSchema = joi.object({
  email: joi
    .string()
    .required()
    .email({ tlds: { allow: false } }),
  role: joi.number().default(0),
  mplacesId: joi.array().items(joi.number()),
});

const MplaceAutocomplete: FunctionComponent<MplaceAutocompleteProps> = ({
  clientId,
  setValue,
  isError = false,
  errorText = '',
  register,
}) => {
  const [mplaceAutoCompleteOpen, setMplaceAutoCompleteOpen] = useState(false);
  const history = useHistory();

  const { data: mplacesData } = useQuery<MeasurementPlacesResponse, ApiError>(
    ['client-mplaces', clientId],
    ApiService.get(`/client/${clientId}/mplace`, { page: 0, rowsPerPage: 1000 }),
    {
      onError: (error) => history.push('/error', { state: { error } }),
    }
  );

  useEffect(() => {
    register('mplacesId');
  }, [register]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      open={mplaceAutoCompleteOpen}
      onOpen={() => setMplaceAutoCompleteOpen(true)}
      onClose={() => setMplaceAutoCompleteOpen(false)}
      onChange={(_, val) => {
        const valArray = val.map(({ value }) => value);
        return val && setValue('mplacesId', valArray);
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.displayValue}
      options={mplacesData ? mplacesData.mplaces.map((el) => ({ value: el.id, displayValue: el.name })) : []}
      noOptionsText="Nema rezultata"
      renderOption={(option, { selected }) => {
        return (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.displayValue}
          </>
        );
      }}
      style={{ width: 500 }}
      renderInput={(params) => {
        return (
          <StyledTextField
            {...params}
            label="Mjerno mjesto"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              error: isError,
              name: 'mplacesId',
            }}
            helperText={errorText}
          />
        );
      }}
    />
  );
};

export const NewUser: FunctionComponent<NewUserProps> = ({
  open,
  handleClose,
  clientId,
  refetch,
  currentMplaceId = null,
}) => {
  const [radioValue, setRadioValue] = useState<string>('client');
  const [mplaceError, setMplaceError] = useState<boolean>(false);

  const { register, handleSubmit, errors, setValue } = useForm<FormInput>({
    resolver: joiResolver(userSchema),
  });

  const [addUser, addUserReqInfo] = useMutation<{ id: number }, ApiError, { path: string; data: FormInput }, unknown>(
    ApiService.post()
  );

  useEffect(() => {
    register('role');
  }, [register]);

  const onSubmit = async (data: FormInput) => {
    setMplaceError(false);
    if (radioValue === 'mplace' && data.mplacesId === undefined) {
      setMplaceError(true);
    } else {
      await addUser({
        path: `/client/${clientId}/user`,
        data,
      });
    }
  };

  const onSuccess = () => {
    refetch();
    handleModalClose();
  };

  const handleModalClose = () => {
    handleClose();
    addUserReqInfo.reset();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setValue('role', event.target.value as number);
  };

  const handleBackdropClick = () => {
    refetch();
    addUserReqInfo.reset();
    handleClose();
  };

  const getModalContent = (): ReactElement => {
    if (addUserReqInfo.isLoading) {
      return <LoadingIndicator />;
    }

    if (addUserReqInfo.error) {
      return (
        <InfoWrapper>
          <Typography variant="body2">{ApiService.getErrorMessage(addUserReqInfo.error.response.data.code)}</Typography>
          <ButtonsWrapper>
            <Button color="primary" onClick={addUserReqInfo.reset}>
              Pokušaj ponovno
            </Button>
            <Button onClick={handleModalClose} color="default" type="button">
              Odustani
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    if (addUserReqInfo.isSuccess) {
      return (
        <InfoWrapper>
          <Typography variant="body2">Korisnik uspješno dodan</Typography>
          <ButtonsWrapper>
            <Button onClick={onSuccess} color="primary" type="button">
              U redu
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      );
    }

    return (
      <>
        <Typography variant="h6">Dodavanje novog korisnika</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <TextField
            inputProps={{ ref: register, name: 'email' }}
            label="Email korisnika"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email ? 'Email adresa je obavezna' : ''}
          />
          <Divider />
          {currentMplaceId ? null : (
            <>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel value="client" control={<Radio />} label="Klijent" />
                <FormControlLabel value="mplace" control={<Radio />} label="Mjerno mjesto" />
              </RadioGroup>
              {radioValue === 'client' ? null : (
                <MplaceAutocomplete
                  setValue={setValue}
                  clientId={clientId}
                  register={register}
                  isError={mplaceError}
                  errorText={mplaceError ? 'Molimo odaberite mjerno mjesto' : ''}
                />
              )}
            </>
          )}
          <FormControl fullWidth variant="standard">
            <InputLabel>Uloga</InputLabel>
            <Select defaultValue={0} onChange={handleRoleChange} inputProps={{ name: 'role' }}>
              <MenuItem value={0}>Čitanje</MenuItem>
              <MenuItem value={10}>Pisanje</MenuItem>
              {radioValue === 'client' ? <MenuItem value={20}>Admin</MenuItem> : null}
            </Select>
          </FormControl>
          <ButtonsWrapper>
            <Button type="submit" color="primary">
              Spremi
            </Button>
            <Button onClick={handleModalClose}>Odustani</Button>
          </ButtonsWrapper>
        </form>
      </>
    );
  };

  return (
    <CustomModal open={open} onClose={handleBackdropClick}>
      {getModalContent()}
    </CustomModal>
  );
};
